// angular chat storage service

import { Injectable } from '@angular/core';
import { StorageService } from '@core/storage/services/storage.service';
import { DeviceService } from '@core/device/services/device.service';

@Injectable({
    providedIn: 'root'
})
export class ChatStorageService {
    constructor(
        private storageService: StorageService,
        private deviceService: DeviceService
    ) {}

    public async shouldUsePopup(): Promise<boolean> {
        return this.storageService.get(await this.popupChatOpenKey()) || false;
    }

    public async setShouldUsePopup(usePopup: boolean): Promise<void> {
        this.storageService.put(await this.popupChatOpenKey(), usePopup);
    }

    public async getPreviousChat(): Promise<string> {
        return this.storageService.get(await this.popupChatPreviousKey()) || '';
    }

    public async setPreviousChat(chatId: string): Promise<void> {
        this.storageService.put(await this.popupChatPreviousKey(), chatId);
    }

    public async clearPreviousChat(): Promise<void> {
        this.storageService.clear(await this.popupChatPreviousKey());
    }

    private readonly popupChatOpenKey = async (): Promise<string> =>
        `${await this.deviceService.getAppName()}-popup-chat-open`;

    private readonly popupChatPreviousKey = async (): Promise<string> =>
        `${await this.deviceService.getAppName()}-popup-chat-previous`;
}
