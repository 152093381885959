import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertNotificationType } from '@core/notifications/enums/alert-notification-type.enum';
import { BusinessCardNotificationType } from '@core/notifications/enums/business-card-notification-type.enum';
import { MeetingNotificationType } from '@core/notifications/enums/meeting-notification-type.enum';
import { ApiDrivenNotification } from '@core/notifications/interfaces/api-driven-notification.interface';
import { CustomNotificationRulesService } from '@core/notifications/services/custom-notifications-rules/custom-notification-rules.service';
import { NotificationsService } from '@core/notifications/services/notifications/notifications.service';
import { AlertsFacadeService } from '@features/alerts/services/alerts-facade.service';
import { AlertsActions } from '@features/alerts/store/actions/alerts.actions';
import { VideoCallIncomingModalService } from '@features/video-calls/services/video-call-incoming-modal.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AlertTypeEnum, AppAlert } from '@shared/api';
import { of, timer } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { AblyMessagingService } from '../../../../ably/services/ably-messaging.service';
import { AppCoreFacadeService } from '../../../../app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '../../../../authentication/services/authentication-facade.service';
import { DeviceService } from '../../../../device/services/device.service';
import { EnvironmentService } from '../../../../environment/services/environment.service';
import { FirebaseService } from '../../../../firebase/services/firebase.service';
import { StorageService } from '../../../../storage/services/storage.service';
import { AppCoreActions } from '../../app-core/actions/app-core.actions';
import { AuthInterceptorActions } from '../../authentication/actions/auth-interceptor.actions';
import { AuthenticationActions } from '../../authentication/actions/authentication.actions';
import { RealtimeActions } from '../actions/realtime.actions';
import { routerNavigatedAction } from '@ngrx/router-store';
import { AppRoutingFacadeService } from '@core/routing/services/app-routing-facade.service';

@Injectable()
export class RealtimeEffects {
    initialiseAbly$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess, AuthenticationActions.completed),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{}, appName]) => {
                const deviceId = this.storageService.getFromStorage(this.deviceService.deviceIdKey);
                return this.ablyMessagingService.initialise(appName, deviceId).pipe(
                    tap(() => this.ablyMessagingService.connectToPublicChannel(appName)),
                    map(() => RealtimeActions.initialiseAblySuccess()),
                    catchError((error) => of(RealtimeActions.initialiseAblyFailure({ error })))
                );
            })
        )
    );

    initialiseFirebase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            switchMap(() => this.authenticationFacadeService.hasAppAccessAndAuthenticated()),
            filter((authenticated) => authenticated),
            withLatestFrom(this.authenticationFacadeService.getUserAuthInfo()),
            switchMap(([, userAuthInfo]) => this.firebaseService.signInWithToken(userAuthInfo.auth.firebase_token)),
            map(() => RealtimeActions.initialiseFirebaseSuccess()),
            catchError((error: HttpErrorResponse) => of(RealtimeActions.initialiseFirebaseFailure({ error })))
        )
    );

    refreshFirebase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthInterceptorActions.refreshTokenSuccess),
            switchMap(({ basicAuth }) => this.firebaseService.signInWithToken(basicAuth.firebase_token)),
            map(() => RealtimeActions.refreshFirebaseSuccess()),
            catchError((error: HttpErrorResponse) => of(RealtimeActions.refreshFirebaseFailure({ error })))
        )
    );

    signOutFirebase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            switchMap(() => this.authenticationFacadeService.hasAppAccessAndAuthenticated()),
            filter((authenticated) => !authenticated),
            switchMap(() => this.firebaseService.signOut()),
            map(() => RealtimeActions.firebaseSignOut())
        )
    );

    updateFirebase$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RealtimeActions.initialiseFirebaseSuccess),
            switchMap(() =>
                timer(0, this.environmentService.firebase.refreshInterval).pipe(
                    withLatestFrom(this.authenticationFacadeService.getAuthenticatedPerson()),
                    filter(([_, authenticatedUser]) => !authenticatedUser.appear_offline),
                    map(() => this.storageService.getFromStorage(this.deviceService.deviceIdKey)),
                    switchMap((deviceId) => this.firebaseService.updatePresence(deviceId)),
                    map(() => RealtimeActions.presenceUpdate()),
                    takeUntil(this.actions$.pipe(ofType(RealtimeActions.firebaseSignOut)))
                )
            )
        )
    );

    connectToPrivateChannel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RealtimeActions.initialiseAblySuccess),
            withLatestFrom(this.authenticationFacadeService.getUserAuthInfo()),
            filter(([_, userAuthInfo]) => !!userAuthInfo),
            map(([_, userAuthInfo]) => userAuthInfo.auth.ably_channel),
            tap((privateChannel) => this.ablyMessagingService.connectToPrivateChannel(privateChannel)),
            map(() => RealtimeActions.connectedToPrivateChannel())
        )
    );

    connectToModuleChannel$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(routerNavigatedAction, RealtimeActions.initialiseAblySuccess),
                withLatestFrom(
                    this.ablyMessagingService.connected$,
                    this.appCoreFacadeService.getAppName(),
                    this.appRoutingFacadeService.getCurrentModuleId()
                ),
                distinctUntilChanged(),
                debounceTime(100),
                filter(([_, connected]) => connected),
                tap(([_, __, appName, moduleId]) => {
                    if (moduleId) {
                        this.ablyMessagingService.connectToModuleChannel(`${appName}:module:${moduleId}`);
                    } else {
                        this.ablyMessagingService.disconnectFromModuleChannel();
                    }
                })
            ),
        { dispatch: false }
    );

    disconnectFromPrivateChannelOnLogout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthenticationActions.logout),
            tap(() => this.ablyMessagingService.disconnectFromPrivateChannel()),
            map(() => RealtimeActions.disconnectedFromPrivateChannel())
        )
    );

    subscribeToVideoCallMessages$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RealtimeActions.connectedToPrivateChannel),
                switchMap(() =>
                    this.ablyMessagingService.getMessageStream(['invite_to_video_call'], true).pipe(
                        takeUntil(this.actions$.pipe(ofType(RealtimeActions.disconnectedFromPrivateChannel))),
                        map(({ data }) => JSON.parse(data)),
                        tap((data) => this.videoCallIncomingModalService.showIncomingVideoCallDialog(data))
                    )
                )
            ),
        { dispatch: false }
    );

    subscribeMeetingNotifications$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RealtimeActions.connectedToPrivateChannel),
                map(() => Object.keys(MeetingNotificationType).map((key) => MeetingNotificationType[key])),
                switchMap((eventNames) =>
                    this.ablyMessagingService.getMessageStream(eventNames, true).pipe(
                        takeUntil(this.actions$.pipe(ofType(RealtimeActions.disconnectedFromPrivateChannel))),
                        map(({ data }) => JSON.parse(data)),
                        map((data) => this.notificationsService.createMeetingApiNotification(data))
                    )
                )
            ),
        { dispatch: false }
    );

    subscribeGeneralNotifications$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RealtimeActions.connectedToPrivateChannel),
                switchMap(() =>
                    this.ablyMessagingService.getMessageStream(['notification_created'], true).pipe(
                        takeUntil(this.actions$.pipe(ofType(RealtimeActions.disconnectedFromPrivateChannel))),
                        map(({ data }) => JSON.parse(data) as ApiDrivenNotification),
                        filter((notification) =>
                            this.customNotificationRulesService.shouldDisplayNotification(notification.event_type)
                        ),
                        map((data) => this.notificationsService.createApiDrivenNotification(data)),
                        tap(() => this.alertsFacadeService.dispatch(AlertsActions.incrementNotificationsBadge()))
                    )
                )
            ),
        { dispatch: false }
    );

    subscribeBusinessCardNotifications$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RealtimeActions.connectedToPrivateChannel),
                map(() => Object.keys(BusinessCardNotificationType).map((key) => BusinessCardNotificationType[key])),
                switchMap((eventNames) =>
                    this.ablyMessagingService.getMessageStream(eventNames, true).pipe(
                        takeUntil(this.actions$.pipe(ofType(RealtimeActions.disconnectedFromPrivateChannel))),
                        map(({ data }) => JSON.parse(data)),
                        map((data) => this.notificationsService.createBusinessCardApiNotification(data))
                    )
                )
            ),
        { dispatch: false }
    );

    subscribeToAlertNotifications$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(RealtimeActions.connectedToPrivateChannel),
                switchMap(() =>
                    this.ablyMessagingService.getMessageStream([AlertNotificationType.Sent], true).pipe(
                        takeUntil(this.actions$.pipe(ofType(RealtimeActions.disconnectedFromPrivateChannel))),
                        map(({ data }) => JSON.parse(data)),
                        filter((alert) => alert.alert_type !== AlertTypeEnum.Popup),
                        switchMap((alert: AppAlert) =>
                            this.authenticationFacadeService.getAuthenticatedPerson().pipe(
                                filter(Boolean),
                                filter((user) => user.peoplegroups.some((id) => alert.groups.includes(id.toString()))),
                                map(() => this.notificationsService.createAlertApiNotification(alert)),
                                tap(() =>
                                    this.alertsFacadeService.dispatch(AlertsActions.incrementNotificationsBadge())
                                ),
                                take(1)
                            )
                        )
                    )
                )
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private appCoreFacadeService: AppCoreFacadeService,
        private ablyMessagingService: AblyMessagingService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private storageService: StorageService,
        private deviceService: DeviceService,
        private firebaseService: FirebaseService,
        private videoCallIncomingModalService: VideoCallIncomingModalService,
        private environmentService: EnvironmentService,
        private notificationsService: NotificationsService,
        private alertsFacadeService: AlertsFacadeService,
        private customNotificationRulesService: CustomNotificationRulesService,
        private appRoutingFacadeService: AppRoutingFacadeService
    ) {}
}
