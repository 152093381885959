import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ChatListActions = createActionGroup({
    source: 'Chat List',
    events: {
        'Go To Chat': props<{ chatId: string }>(),
        'Enter List Page': emptyProps(),
        'Leave Page': emptyProps(),
        'Submit Search': props<{ searchTerm: string }>()
    }
});
