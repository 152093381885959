import { createSelector } from '@ngrx/store';
import { AppState } from '../../app-state.model';

const selectAuthenticationState = (state: AppState) => state.authentication;

const selectUserAuthInfo = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.userAuthInfo;
});

const selectAuthenticatedPerson = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.authenticatedPerson;
});

const selectAnonymousToken = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.anonymousToken;
});

const selectPasscodeTokens = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.passcodeTokens;
});

const selectUser = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.userAuthInfo ? authenticationState.userAuthInfo.user : undefined;
});

const selectAnonymousTokenIfUnauthenticated = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.userAuthInfo ? undefined : authenticationState.anonymousToken;
});

const selectIsAuthenticated = createSelector(selectAuthenticationState, (authenticationState) => {
    return !!authenticationState.userAuthInfo;
});

const selectUserEmailInfo = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.userEmailInfo;
});

const selectRedirectUrl = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.redirectUrl;
});

const selectHasDismissedMfa = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.hasDismissedMfa;
});

const selectOtpSignature = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.otpSignature;
});

const selectAppIdentityProviders = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.providers;
});

const selectSsoHasSettled = createSelector(selectAuthenticationState, (authenticationState) => {
    return authenticationState.ssoHasSettled;
});

export const AuthenticationSelectors = {
    selectUser,
    selectAnonymousTokenIfUnauthenticated,
    selectUserAuthInfo,
    selectAuthenticatedPerson,
    selectAnonymousToken,
    selectPasscodeTokens,
    selectIsAuthenticated,
    selectUserEmailInfo,
    selectRedirectUrl,
    selectHasDismissedMfa,
    selectOtpSignature,
    selectAppIdentityProviders,
    selectSsoHasSettled
};
