import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomRoute } from '@core/routing/types/custom-route';
import { ChatListComponent } from '@features/chat/smart-components/chat-list/chat-list.component';

const routes: CustomRoute[] = [
    {
        path: '',
        component: ChatListComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserChatRoutingModule {}
