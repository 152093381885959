import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ChatDetailActions } from '../actions/chat-detail.actions';
import { catchError, filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AppCoreFacadeService } from '../../../../core/app-core/services/app-core-facade.service';
import { ChatFacadeService } from '../../services/chat-facade.service';
import { AuthenticationFacadeService } from '../../../../core/authentication/services/authentication-facade.service';
import { forkJoin, of } from 'rxjs';
import { ChatApiActions } from '../actions/chat-api.actions';
import { ChatApiService } from '../../services/chat-api.service';
import { PeopleApiService } from '../../../people/services/people-api.service';
import { ChatActions } from '../actions/chat.actions';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ChatDetailEffects {
    fetchChatDetailData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatDetailActions.enter),
            withLatestFrom(
                this.appCoreFacadeService.getAppName(),
                this.authenticationFacadeService.getAuthenticatedPerson()
            ),
            switchMap(([{ chatId }, appUrl, loggedInUser]) => {
                return forkJoin([
                    this.chatApiService.getChat(appUrl, chatId),
                    this.chatApiService.getMessages(appUrl, chatId)
                ]).pipe(
                    switchMap(([chat, messages]) => {
                        const peerId = chat.peers.find((p) => p.id !== loggedInUser.id).id;
                        return this.peopleApiService.getPerson(appUrl, peerId).pipe(
                            map((peer) =>
                                ChatApiActions.getChatDetailSuccess({
                                    chatId,
                                    chat,
                                    messages,
                                    peer
                                })
                            )
                        );
                    }),
                    catchError((error: HttpErrorResponse) => of(ChatApiActions.getChatDetailFailure({ error })))
                );
            })
        )
    );

    markReadOnEnter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatApiActions.getChatDetailSuccess),
            withLatestFrom(
                this.chatFacadeService.getChats(),
                this.authenticationFacadeService.getAuthenticatedPerson()
            ),
            filter(([{ chatId }, chats, loggedInUser]) => {
                const existingChat = chats.find((c) => c.id === chatId);
                // Mark it as read if there isnt a last message, or there is and it hasnt been read
                return (
                    !existingChat.r_last_message ||
                    (existingChat.r_last_message && !existingChat.r_last_message.read_by.includes(loggedInUser.id))
                );
            }),
            map(([{ chatId }]) => ChatDetailActions.markLatestRead({ chatId }))
        )
    );

    markRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatDetailActions.markLatestRead),
            withLatestFrom(
                this.appCoreFacadeService.getAppName(),
                this.authenticationFacadeService.getAuthenticatedPerson()
            ),
            switchMap(([{ chatId }, appUrl, loggedInUser]) =>
                this.chatApiService.markRead(appUrl, chatId).pipe(
                    map(() => ChatApiActions.markReadSuccess({ chatId, loggedInUserId: loggedInUser.id })),
                    catchError((error: HttpErrorResponse) => of(ChatApiActions.markReadFailure({ error })))
                )
            )
        )
    );

    markRealtimeMessageRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatDetailActions.enter),
            switchMap(({ chatId }) =>
                this.actions$.pipe(
                    ofType(ChatActions.realtimeNewMessage),
                    filter(({ message }) => message.chat === chatId),
                    takeUntil(this.actions$.pipe(ofType(ChatDetailActions.leave)))
                )
            ),
            map(({ message: { chat: chatId } }) => ChatDetailActions.markLatestRead({ chatId }))
        )
    );

    sendMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatDetailActions.sendMessage),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ chatId, text }, appUrl]) =>
                this.chatApiService.sendMessage(appUrl, chatId, text).pipe(
                    map((message) => ChatApiActions.sendMessageSuccess({ message })),
                    catchError((error: HttpErrorResponse) => of(ChatApiActions.sendMessageFailure({ error })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private appCoreFacadeService: AppCoreFacadeService,
        private chatApiService: ChatApiService,
        private chatFacadeService: ChatFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private peopleApiService: PeopleApiService
    ) {}
}
