<ng-container
    *ngIf="{
        view: view$ | async,
        chatId: chatId$ | async,
        detailPeer: detailPeer$ | async,
        canVideoCall: canVideoCall$ | async,
        appUrl: appUrl$ | async
    } as popupData"
>
    <ion-toolbar class="chat-popup__header">
        <ion-buttons class="chat-popup__start-buttons" slot="start">
            <ion-button
                *ngIf="popupData.view === ChatPopupView.Detail"
                class="chat-popup__back-button"
                (click)="enterList()"
            >
                <i class="material-icons" slot="icon-only">chevron_left</i>
            </ion-button>

            <div *ngIf="popupData.view === ChatPopupView.List" class="chat-popup__chat-icon">
                <i class="material-icons">chat</i>
            </div>
        </ion-buttons>

        <ion-title class="chat-popup__title">
            <ng-container *ngIf="popupData.view === ChatPopupView.List">
                {{ 'CHAT_POPUP_TITLE' | translate }}
            </ng-container>

            <ng-container *ngIf="popupData.view === ChatPopupView.Detail">
                <a
                    *ngIf="popupData.detailPeer"
                    class="chat-popup__peer-name"
                    [routerLink]="[popupData.appUrl, 'people', popupData.detailPeer.id]"
                >
                    <cc-person-name excludes="title" [person]="popupData.detailPeer"></cc-person-name>
                </a>
            </ng-container>
        </ion-title>

        <ion-buttons class="chat-popup__end-buttons" slot="end">
            <ion-button
                *ngIf="popupData.canVideoCall"
                class="chat-popup__video-call-button"
                (click)="videoCall(popupData.detailPeer)"
            >
                <i class="material-icons" slot="icon-only">videocam</i>
            </ion-button>

            <ion-button class="chat-popup__close-button" (click)="close()">
                <i class="material-icons" slot="icon-only">close</i>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>

    <cc-chat-list *ngIf="popupData.view === ChatPopupView.List"></cc-chat-list>
    <cc-chat-detail *ngIf="popupData.view === ChatPopupView.Detail" [chatId]="popupData.chatId"></cc-chat-detail>
</ng-container>
