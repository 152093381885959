import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ChatPopupService } from '@features/chat/services/chat-popup/chat-popup.service';
import { map } from 'rxjs/operators';
import { ChatFacadeService } from '@features/chat/services/chat-facade.service';
import { ChatListActions } from '@features/chat/store/actions/chat-list.actions';
import { ChatRouteParams } from '@features/chat/constants/chat-route-params.constant';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';

// If the user lands on the chat detail page (e.g. link from email) and the chat popup is enabled
// then open the chat popup and navigate them to homepage
export function chatPagePopupGuard(): CanActivateFn {
    return (route: ActivatedRouteSnapshot) => {
        const chatPopupService = inject(ChatPopupService);
        const chatFacadeService = inject(ChatFacadeService);
        const router = inject(Router);

        return chatPopupService.usePopup().pipe(
            map((usePopup) => {
                if (!usePopup) {
                    return true;
                }

                const chatId = route.paramMap.get(ChatRouteParams.chatId);
                chatFacadeService.dispatch(ChatListActions.goToChat({ chatId }));

                // If the user hasn't previously navigated e.g. they landed on the chat detail page from an email
                // then navigate them to the homepage as a fallback
                if (!router.navigated) {
                    chatFacadeService.dispatch(AppCoreActions.navigateHome());
                }

                return false;
            })
        );
    };
}
