import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatFacadeService } from '@features/chat/services/chat-facade.service';
import { ChatDetailActions } from '@features/chat/store/actions/chat-detail.actions';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { ChatMessageComponent } from '@features/chat/components/chat-message/chat-message.component';
import { PersonHeaderActions } from '@shared/people/types/person-header-actions.type';
import { PersonHeaderAction } from '@shared/people/enums/person-header-action.enum';
import { fadeIn } from '@common/animations/animations';
import { ChatMessage, People } from '@shared/api';
import { ChatStorageService } from '@features/chat/services/chat-storage/chat-storage.service';

@Component({
    selector: 'cc-chat-detail',
    templateUrl: './chat-detail.component.html',
    styleUrls: ['./chat-detail.component.scss'],
    animations: [fadeIn()]
})
export class ChatDetailComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChildren('chatMessages')
    public chatMessages: QueryList<ChatMessageComponent>;

    @Input()
    public chatId: string;

    @Input()
    public displayHeader: boolean;

    public loggedInUser$: Observable<People>;
    public initialising$: Observable<boolean>;
    public messages$: Observable<ChatMessage[]>;
    public peer$: Observable<People>;

    public headerActions: PersonHeaderActions = { [PersonHeaderAction.Chat]: false };

    private readonly timeBreak = 5;

    constructor(
        private chatFacadeService: ChatFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private chatStorageService: ChatStorageService
    ) {}

    public ngOnInit(): void {
        this.peer$ = this.chatFacadeService.getDetailPeer();
        this.initialising$ = this.chatFacadeService.getDetailInitialising();
        this.loggedInUser$ = this.authenticationFacadeService.getAuthenticatedPerson();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.chatId) {
            this.chatFacadeService.dispatch(
                ChatDetailActions.enter({
                    chatId: this.chatId
                })
            );
            this.messages$ = this.chatFacadeService.getMessages(this.chatId);
            this.chatStorageService.setPreviousChat(this.chatId);
        }
    }

    public onSend(message: string): void {
        this.chatFacadeService.dispatch(ChatDetailActions.sendMessage({ chatId: this.chatId, text: message }));
    }

    public isTimeBreak(index: number, chatA: ChatMessage, chatB: ChatMessage): boolean {
        if (index === 0) {
            return true;
        }
        if (!chatB) {
            return false;
        }
        return (
            Math.abs(new Date(chatA.created).getTime() - new Date(chatB.created).getTime()) > this.timeBreak * 1000 * 60
        );
    }

    public ngOnDestroy(): void {
        this.chatFacadeService.dispatch(ChatDetailActions.leave());
        this.chatStorageService.clearPreviousChat();
    }
}
