import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { chatStoreKey } from './models/chat.state.model';
import { chatReducer } from './reducers/chat.reducer';
import { ChatDetailEffects } from './effects/chat-detail.effects';
import { ChatEffects } from './effects/chat.effects';
import { ChatPopupEffects } from '@features/chat/store/effects/chat-popup.effects';

@NgModule({
    imports: [
        StoreModule.forFeature(chatStoreKey, chatReducer),
        EffectsModule.forFeature([ChatEffects, ChatDetailEffects, ChatPopupEffects])
    ]
})
export class ChatStoreModule {}
