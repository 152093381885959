import { Injectable } from '@angular/core';
import { People, PeopleMinimal } from '@api';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { ExperimentalService } from '@core/environment/services/experimental.service';
import { GlobalSettingsFacadeService } from '@core/global-settings/services/global-settings-facade.service';
import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';
import { AppRoutingFacadeService } from '@core/routing/services/app-routing-facade.service';
import { ChatFacadeService } from '@features/chat/services/chat-facade.service';
import { ChatActions } from '@features/chat/store/actions/chat.actions';
import { MeetingBookingQueryParams } from '@features/meeting-booking/constants/meeting-booking-query-params.constant';
import { ExpandedPeople } from '@features/people/models/expanded-people.model';
import { PeopleFacadeService } from '@features/people/services/people-facade.service';
import { PeopleDetailActions } from '@features/people/store/actions/people-detail.actions';
import { VideoCallService } from '@features/video-calls/services/video-call.service';
import { ImageViewerService } from '@shared/images/services/image-viewer/image-viewer.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PersonActionService {
    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private imageViewerService: ImageViewerService,
        private chatFacadeService: ChatFacadeService,
        private videoCallService: VideoCallService,
        private peopleFacadeService: PeopleFacadeService,
        private appRoutingFacadeService: AppRoutingFacadeService,
        private globalSettingsFacadeService: GlobalSettingsFacadeService
    ) {}

    public canUseMeetingBooking(person: People | ExpandedPeople | PeopleMinimal): Observable<boolean> {
        return combineLatest([
            this.appCoreFacadeService.getAppSettings(),
            this.authenticationFacadeService.getAuthenticatedPerson(),
            this.globalSettingsFacadeService.getCanCreateDelegateMeetings()
        ]).pipe(
            map(
                ([app, loggedInUser, canCreateDelegateMeetings]) =>
                    loggedInUser?.meeting_booking_enabled &&
                    loggedInUser?.meeting_booking_allowed &&
                    person?.meeting_booking_enabled &&
                    (person?.meeting_booking_allowed || !ExperimentalService.isExperimentalModeOn()) &&
                    app.mbookings_enabled &&
                    person.id !== loggedInUser.id &&
                    canCreateDelegateMeetings
            )
        );
    }

    public canUseBusinessCards(person: People | ExpandedPeople): Observable<boolean> {
        return combineLatest([this.authenticationFacadeService.getAuthenticatedPerson()]).pipe(
            map(
                ([loggedInUser]) =>
                    loggedInUser?.business_cards_enabled &&
                    loggedInUser?.can_exchange_business_cards &&
                    person?.business_cards_enabled &&
                    person?.can_exchange_business_cards &&
                    person.id !== loggedInUser.id
            )
        );
    }

    public canChat(person: People | ExpandedPeople | PeopleMinimal): Observable<boolean> {
        return combineLatest([
            this.appCoreFacadeService.getAppSettings(),
            this.authenticationFacadeService.getAuthenticatedPerson()
        ]).pipe(
            map(
                ([app, loggedInUser]) =>
                    loggedInUser &&
                    loggedInUser.chat_enabled &&
                    app.can_chat &&
                    person.chat_enabled &&
                    loggedInUser.id !== person.id
            )
        );
    }

    public canVideoCall(person: People | ExpandedPeople | PeopleMinimal): Observable<boolean> {
        return combineLatest([
            this.appCoreFacadeService.getAppSettings(),
            this.authenticationFacadeService.getAuthenticatedPerson()
        ]).pipe(
            map(
                ([app, loggedInUser]) =>
                    person &&
                    loggedInUser &&
                    app.enable_delegate_video_calls &&
                    person.accepts_video_calls &&
                    loggedInUser.id !== person.id
            )
        );
    }

    public zoomPicture(person: People | ExpandedPeople): void {
        if (!person.app_picture) {
            return;
        }

        this.imageViewerService.show(person.app_picture);
    }

    public chat(person: People | ExpandedPeople | PeopleMinimal): void {
        this.chatFacadeService.dispatch(ChatActions.openChat({ personId: person.id }));
    }

    public videoCall(person: People | ExpandedPeople | PeopleMinimal): void {
        this.videoCallService.openNewWindow();
        this.peopleFacadeService.dispatch(PeopleDetailActions.openVideoChat({ person }));
    }

    public scheduleMeeting(person: People | ExpandedPeople | PeopleMinimal): void {
        this.appRoutingFacadeService.dispatch(
            AppRoutingActions.goToAppUserPage({
                urlSegments: [AppUserPageRoutes.meetings],
                extras: {
                    queryParams: {
                        [MeetingBookingQueryParams.personId]: person.id
                    }
                }
            })
        );
    }
}
