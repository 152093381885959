import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ChatMessage } from '@shared/api';

export const ChatActions = createActionGroup({
    source: 'Chat',
    events: {
        'Open Chats': emptyProps(),
        'Refresh Chats': emptyProps(),
        'Realtime New Message': props<{ message: ChatMessage }>(),
        'Open Chat': props<{ personId: number }>(),
        'Clear Chat': emptyProps()
    }
});
