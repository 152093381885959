import { Injectable } from '@angular/core';
import { Chat } from '@shared/api';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ChatMessage } from '@shared/api';
import { ApiSettings } from '../../../shared/settings/api-settings.constant';
import { InlineChat } from '../interfaces/inline-chat';

@Injectable({
    providedIn: 'root'
})
export class ChatApiService {
    constructor(private httpClient: HttpClient) {}

    public createChat(appUrl: string, personId: number, loggedInUserId: number): Observable<Chat> {
        return this.httpClient.post<Chat>(ApiSettings.fullUrl(appUrl, ChatEndpoints.chats), {
            app: appUrl,
            peers: [personId, loggedInUserId]
        });
    }

    public sendMessage(appUrl: string, chatId: string, text: string): Observable<ChatMessage> {
        return this.httpClient.post<ChatMessage>(ApiSettings.fullUrl(appUrl, ChatEndpoints.messages(chatId)), {
            app: appUrl,
            chat: chatId,
            text
        });
    }

    public markRead(appUrl: string, chatId: string): Observable<any> {
        return this.httpClient.post(ApiSettings.fullUrl(appUrl, ChatEndpoints.markRead(chatId)), null);
    }

    public getChat(appName: string, chatId: string): Observable<InlineChat> {
        const url = ApiSettings.fullUrl(appName, ChatEndpoints.chat(chatId));
        return this.httpClient.get<InlineChat>(url);
    }

    public getMessages(appName: string, chatId: string): Observable<ChatMessage[]> {
        const url = ApiSettings.fullUrl(appName, ChatEndpoints.messages(chatId));
        return this.httpClient.get<ChatMessage[]>(url);
    }

    public getChats(appName: string): Observable<InlineChat[]> {
        const url = ApiSettings.fullUrl(appName, ChatEndpoints.chats);
        return this.httpClient.get<InlineChat[]>(url);
    }
}

export const ChatEndpoints = {
    chats: '/chats/?expand=peers',
    messages: (chatId: string) => `/chats/${chatId}/messages/`,
    chat: (chatId: string) => `/chats/${chatId}/?expand=peers`,
    markRead: (chatId: string) => `/chats/${chatId}/mark-read/`
};
