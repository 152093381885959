import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, props } from '@ngrx/store';
import { ChatMessage, People, SlimPeople } from '@shared/api';
import { InlineChat } from '../../interfaces/inline-chat';

export const ChatApiActions = createActionGroup({
    source: 'Chat Api',
    events: {
        'Get Chats Success': props<{ chats: InlineChat[] }>(),
        'Get Chats Failure': props<{ error: HttpErrorResponse }>(),
        'Get Chat Detail Success': props<{ chatId: string; chat: InlineChat; messages: ChatMessage[]; peer: People }>(),
        'Get Chat Detail Failure': props<{ error: HttpErrorResponse }>(),
        'Send Message Success': props<{ message: ChatMessage }>(),
        'Send Message Failure': props<{ error: HttpErrorResponse }>(),
        'Mark Read Success': props<{ chatId: string; loggedInUserId: number }>(),
        'Mark Read Failure': props<{ error: HttpErrorResponse }>(),
        'Get Inline Realtime Chat Success': props<{ chat: InlineChat; message: ChatMessage }>(),
        'Get Inline Realtime Chat Failure': props<{ error: HttpErrorResponse }>(),
        'Create Chat Success': props<{ chat: InlineChat }>(),
        'Create Chat Failure': props<{ error: HttpErrorResponse }>(),
        'Get potential chats Success': props<{ potentialChats: SlimPeople[] }>(),
        'Get potential chats Failure': props<{ error: HttpErrorResponse }>()
    }
});
