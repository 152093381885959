import { APP_BASE_HREF, DOCUMENT, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MfaInterceptor } from '@core/authentication/interceptors/mfa.interceptor';
import { RateLimitInterceptor } from '@core/error-handling/interceptors/rate-limit.interceptor';
import { ServerUnresponsiveInterceptor } from '@core/error-handling/interceptors/server-unresponsive.interceptor';
import { UnauthorisedInterceptor } from '@core/error-handling/interceptors/unauthorised.interceptor';
import { OneSignalInterceptor } from '@core/native/interceptors/one-signal.interceptor';
import { IonicModule, Platform } from '@ionic/angular';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { CookieService } from 'ngx-cookie-service';
import { LottieModule } from 'ngx-lottie';
import { QuillModule } from 'ngx-quill';
import { environment } from 'src/environments/environment';
import { Version } from 'src/environments/version';
import { AppConfigService } from './app-config.service';
import { AppLoadModule } from './app-load.module';
import { AppComponent } from './app.component';
import { ServiceLocator } from './core/angular/services/service-locator.service';
import { AuthInterceptor } from './core/authentication/interceptors/auth.interceptor';
import { HrefInterceptorService } from './core/browser-utilities/services/href-interceptor.service';
import { CoreModule } from './core/core.module';
import { DeviceInterceptor } from './core/device/interceptors/device.interceptor';
import { DeviceService } from './core/device/services/device.service';
import { ErrorHandlingModule } from './core/error-handling/error-handling.module';
import { ErrorHandlingInterceptor } from './core/error-handling/interceptors/error-handling.interceptor';
import { I18nModule } from './core/i18n/i18n.module';
import { LanguageInterceptor } from './core/i18n/interceptors/language.interceptor';
import { MissingTranslationHelper } from './core/i18n/missing-translation-handler';
import { I18nPruningTranslationLoader } from './core/i18n/services/i18n-pruning-translation-loader';
import { NativeModule } from './core/native/native.module';
import { AppRoutingModule } from './core/routing/app-routing.module';
import { FeaturesModule } from './features/features.module';
import { UrlUtilityService } from './shared/utilities/url-utility.service';

export function LottiePlayerFactory(): any {
    return import('lottie-web');
}

export function HttpLoaderFactory(http: HttpClient): any {
    return new I18nPruningTranslationLoader(http, './assets/i18n/', '.json?cache=' + Version);
}

export function HrefInterceptorFactory(document: Document, urlUtilityService: UrlUtilityService): any {
    return () => new HrefInterceptorService(document, urlUtilityService);
}

// Load config from json file
const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
        return appConfig.loadAppConfig();
    };
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppLoadModule,
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        ErrorHandlingModule,
        FeaturesModule,
        HttpClientModule,
        LottieModule.forRoot({ player: LottiePlayerFactory }),
        IonicModule.forRoot({
            mode: 'md'
        }),
        LeafletModule,
        I18nModule,
        NativeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper }
        }),
        TooltipModule,
        QuillModule.forRoot(),
        AppRoutingModule,
        ServiceWorkerModule.register(`${window.location.origin}/ngsw-worker.js`, {
            enabled: environment.PWA_ENABLED,
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MfaInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RateLimitInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlingInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorisedInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DeviceInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OneSignalInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerUnresponsiveInterceptor,
            multi: true
        },
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: HrefInterceptorFactory,
            multi: true,
            deps: [DOCUMENT, UrlUtilityService]
        },
        { provide: APP_BASE_HREF, useValue: window.location.origin },
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private platform: Platform,
        private deviceService: DeviceService,
        private injector: Injector
    ) {
        if (this.deviceService.isNative()) {
            this.platform.ready();
        }
        ServiceLocator.injector = this.injector;
    }
}
