import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ChatListActions } from '../actions/chat-list.actions';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppCoreActions } from '../../../../core/root-store/store/app-core/actions/app-core.actions';
import { AuthenticationFacadeService } from '../../../../core/authentication/services/authentication-facade.service';
import { AblyMessagingService } from '../../../../core/ably/services/ably-messaging.service';
import { ChatActions } from '../actions/chat.actions';
import { ChatMessage } from '@shared/api';
import { AppCoreFacadeService } from '../../../../core/app-core/services/app-core-facade.service';
import { forkJoin, of } from 'rxjs';
import { ChatApiActions } from '../actions/chat-api.actions';
import { PeopleApiService } from '../../../people/services/people-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginPromptService } from '../../../login/services/login-prompt.service';
import { ChatApiService } from '../../services/chat-api.service';
import { ChatFacadeService } from '../../services/chat-facade.service';
import { InlineChatPeer } from '../../interfaces/inline-chat';
import { AppPageRoutes } from '../../../../core/routing/constants/app-page-routes.constant';
import { AppRoutingActions } from '../../../../core/root-store/store/app-routing/actions/app-routing.actions';
import { ChatPopupActions } from '@features/chat/store/actions/chat-popup.actions';
import { ChatPopupService } from '@features/chat/services/chat-popup/chat-popup.service';
import { SoundEffects } from '@common/constants/sound-effects.enum';
import { SoundService } from '@common/services/sound/sound.service';
import { AppUserPageRoutes } from '@core/routing/constants/user-page-routes.constant';

// TODO Remove once chat message events are only sent to proper users
interface AblyChatMessage extends ChatMessage {
    inline_chat?: {
        peers: number[];
        id: string;
        app: string;
    };
}

@Injectable()
export class ChatEffects {
    fetchChats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            switchMap(() => this.authenticationFacadeService.hasAppAccessAndAuthenticated()),
            filter((accessAndAuth) => accessAndAuth),
            withLatestFrom(
                this.appCoreFacadeService.getAppName(),
                this.authenticationFacadeService.getAuthenticatedPerson()
            ),
            switchMap(([, appUrl]) =>
                this.chatApiService.getChats(appUrl).pipe(
                    map((chats) => ChatApiActions.getChatsSuccess({ chats })),
                    catchError((error: HttpErrorResponse) => of(ChatApiActions.getChatsFailure({ error })))
                )
            )
        )
    );

    listenForChatMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            switchMap(() => this.authenticationFacadeService.isAuthenticated()),
            filter((authenticated) => authenticated),
            switchMap(() =>
                this.ablyMessagingService.getMessageStream(['chatmessage']).pipe(
                    map(({ data }) => JSON.parse(data) as AblyChatMessage),
                    // TODO Remove once chat message events are only sent to proper users
                    // Messages with inline_chat dont relate to the logged in user
                    filter((message) => !message.inline_chat)
                )
            ),
            withLatestFrom(this.chatFacadeService.getChats()),
            filter(([message, chats]) => !!chats.find((c) => c.id === message.chat)),
            map(([message]) => ChatActions.realtimeNewMessage({ message })),
            filter((action) => !!action)
        )
    );

    playSoundOnNewRealtimeChatMessage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChatActions.realtimeNewMessage),
                tap(() => this.soundService.play(SoundEffects.incomingChat, 0.2))
            ),
        { dispatch: false }
    );

    // Ably events no longer sent out for new chats, must add new chat to store and then insert chat message from ably
    listenForNewChats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            switchMap(() => this.authenticationFacadeService.isAuthenticated()),
            filter((authenticated) => authenticated),
            switchMap(() =>
                this.ablyMessagingService.getMessageStream(['chatmessage']).pipe(
                    map(({ data }) => JSON.parse(data) as AblyChatMessage),
                    // TODO Remove once chat message events are only sent to proper users
                    // Messages with inline_chat dont relate to the logged in user
                    filter((message) => !message.inline_chat)
                )
            ),
            withLatestFrom(this.chatFacadeService.getChats(), this.appCoreFacadeService.getAppName()),
            filter(([message, chats]) => !chats.find((c) => c.id === message.chat)),
            switchMap(([message, , appUrl]) =>
                this.chatApiService.getChat(appUrl, message.chat).pipe(
                    map((chat) =>
                        ChatApiActions.getInlineRealtimeChatSuccess({
                            chat,
                            message
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            ChatApiActions.getInlineRealtimeChatFailure({
                                error
                            })
                        )
                    )
                )
            )
        )
    );

    createChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.openChat),
            withLatestFrom(
                this.appCoreFacadeService.getAppName(),
                this.authenticationFacadeService.getAuthenticatedPerson(),
                this.chatFacadeService.getChats()
            ),
            filter(([, , loggedInUser]) => {
                if (!loggedInUser) {
                    this.loginPromptService.showPrompt('You must be logged in to chat with another user');
                    return false;
                }
                return true;
            }),
            switchMap(([{ personId }, appUrl, loggedInUser, chats]) => {
                const chatAlreadyCreated = chats.find((c) => c.peers.find((p) => p.id === personId));
                if (chatAlreadyCreated) {
                    return of(ChatApiActions.createChatSuccess({ chat: chatAlreadyCreated }));
                }
                return forkJoin([
                    this.chatApiService.createChat(appUrl, personId, loggedInUser.id),
                    this.peopleApiService.getPerson(appUrl, personId)
                ]).pipe(
                    map(([chat, peer]) =>
                        ChatApiActions.createChatSuccess({
                            chat: {
                                ...chat,
                                peers: [peer, loggedInUser] as InlineChatPeer[]
                            }
                        })
                    ),
                    catchError((error: HttpErrorResponse) => of(ChatApiActions.createChatFailure({ error })))
                );
            })
        )
    );

    navigateToNewChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatApiActions.createChatSuccess),
            map(({ chat }) => ChatListActions.goToChat({ chatId: chat.id }))
        )
    );

    clearChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            switchMap(() => this.authenticationFacadeService.hasAppAccessAndAuthenticated()),
            filter((accessAndAuth) => !accessAndAuth),
            map(() => ChatActions.clearChat())
        )
    );

    navigateToChat$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatListActions.goToChat),
            withLatestFrom(this.chatPopupService.usePopup()),
            switchMap(([{ chatId }, usePopup]) =>
                usePopup
                    ? [ChatPopupActions.enterDetail({ chatId }), ChatPopupActions.open()]
                    : [AppRoutingActions.goToAppPage({ urlSegments: [AppPageRoutes.chat, chatId] })]
            )
        )
    );

    navigateToChats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatActions.openChats),
            withLatestFrom(this.chatPopupService.usePopup(), this.chatFacadeService.getPopupOpen()),
            map(([, usePopup, popupOpen]) => {
                if (!usePopup) {
                    return AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.chat] });
                }
                if (popupOpen) {
                    return ChatPopupActions.close();
                }
                if (!popupOpen) {
                    return ChatPopupActions.open();
                }
            })
        )
    );

    searchForPotentialChats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ChatListActions.submitSearch),
            withLatestFrom(this.appCoreFacadeService.getAppName()),
            switchMap(([{ searchTerm }, appUrl]) =>
                this.peopleApiService
                    .getAutocompletePaginatedPeople({
                        appUrl,
                        query: {
                            search: searchTerm,
                            page: 0,
                            limit: 20
                        },
                        filters: ['canChat']
                    })
                    .pipe(
                        map(({ results }) => ChatApiActions.getPotentialChatsSuccess({ potentialChats: results })),
                        catchError((error: HttpErrorResponse) => of(ChatApiActions.getPotentialChatsFailure({ error })))
                    )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private authenticationFacadeService: AuthenticationFacadeService,
        private ablyMessagingService: AblyMessagingService,
        private appCoreFacadeService: AppCoreFacadeService,
        private chatApiService: ChatApiService,
        private peopleApiService: PeopleApiService,
        private loginPromptService: LoginPromptService,
        private chatFacadeService: ChatFacadeService,
        private chatPopupService: ChatPopupService,
        private soundService: SoundService
    ) {}
}
