import {
    Component,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    AfterViewInit,
    ChangeDetectorRef
} from '@angular/core';
import { Router } from '@angular/router';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { BannerFacadeService } from '@core/banner/services/banner-facade/banner-facade.service';
import { App, MableUser } from '@shared/api';
import { MuxStreamType } from '@shared/mux/enums/mux-stream-type.enum';
import { Observable, Subject } from 'rxjs';
import '@mux/mux-player';
import MuxPlayerElement from '@mux/mux-player';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PlaybackTokens } from '@shared/mux/interfaces/playback-tokens.interface';
import { fadeOut } from '@common/animations/animations';
import { EnvironmentService } from '@core/environment/services/environment.service';

@UntilDestroy()
@Component({
    selector: 'cc-mux-player',
    templateUrl: './mux-player.component.html',
    styleUrls: ['./mux-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeOut()]
})
export class MuxPlayerComponent implements OnInit, OnChanges, AfterViewInit {
    @Input()
    public streamType?: MuxStreamType = MuxStreamType.OnDemand;

    @Input()
    public contentId?: string | number;

    @Input()
    public contentTitle?: string;

    @Input()
    public isSimulive?: boolean = false;

    @Input()
    public autoPlay?: boolean = false;

    @Input()
    public loop?: boolean = false;

    @Input()
    public muted?: boolean = false;

    @Input()
    public startTime?: number;

    @Input()
    public currentTime?: number;

    @Input()
    public hideSeekControls?: boolean;

    @Input()
    public enterPip: Subject<void>;

    @Input()
    public pause: Subject<void>;

    @Input()
    public playbackTokens: PlaybackTokens;

    @Input()
    public overrideSrc: string;

    @Input()
    public poster: string;

    @Output()
    public videoLoaded: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public videoPlayed: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public videoPaused: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public videoEnded: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('muxPlayer')
    public muxPlayerElementRef: ElementRef<MuxPlayerElement>;

    public app$: Observable<App>;
    public user$: Observable<MableUser>;
    public cookieConsent$: Observable<boolean>;

    public analyticsTitle: string;
    public showUnmuteButton: boolean;
    public analyticsMuxDataKey: string;

    private muxPlayerElement: MuxPlayerElement;
    private isPlayingInPip: boolean;
    private playbackRoute: string;
    private hasUnloaded: boolean;

    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private bannerFacadeService: BannerFacadeService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private environmentService: EnvironmentService
    ) {}

    public ngOnInit(): void {
        this.app$ = this.appCoreFacadeService.getAppSettings();
        this.user$ = this.authenticationFacadeService.getUser();
        this.cookieConsent$ = this.bannerFacadeService.selectAllowAnalytics();
        this.playbackRoute = this.router.url;
        this.analyticsMuxDataKey = this.environmentService.muxDataKey;

        this.subscribeToEnterPip();
        this.subscribeToPause();
    }

    public ngAfterViewInit(): void {
        this.muxPlayerElement = this.muxPlayerElementRef.nativeElement;
        // This is to trigger mux to regrab the metadata from the attributes.
        this.muxPlayerElement.metadata = { ...this.muxPlayerElement.metadata };
    }

    public ngOnChanges({ contentTitle, contentId, currentTime }: SimpleChanges): void {
        if (this.contentTitle && this.contentId && (contentId || contentTitle)) {
            this.setAnalyticsTitle();
        }

        if (currentTime && this.muxPlayerElement) {
            this.muxPlayerElement.currentTime = this.currentTime;
        }
    }

    public onVideoLoaded(): void {
        this.videoLoaded.emit();
        this.registerVideoEvents();
        this.showUnmuteButton = this.muxPlayerElement.media.muted;
    }

    public onVideoPlayed(): void {
        this.videoPlayed.emit();
    }

    public onVideoPaused(): void {
        this.videoPaused.emit();
    }

    public onVideoEnded(): void {
        this.videoEnded.emit();
    }

    public onVideoVolumeChange(): void {
        if (this.showUnmuteButton) {
            this.showUnmuteButton = false;
            this.changeDetectorRef.markForCheck();
        }
    }

    public onUnmuteClicked(): void {
        this.muxPlayerElement.media.muted = false;
        this.showUnmuteButton = false;
    }

    private setAnalyticsTitle(): void {
        this.analyticsTitle = `${this.contentTitle} (${this.contentId})`;
    }

    private registerVideoEvents(): void {
        (this.muxPlayerElement.media as any).unloadOriginal = this.muxPlayerElement.media.unload;
        this.muxPlayerElement.media.onenterpictureinpicture = this.onVideoEnterPip.bind(this);
        this.muxPlayerElement.media.onleavepictureinpicture = this.onVideoExitPip.bind(this);
        this.muxPlayerElement.media.onvolumechange = this.onVideoVolumeChange.bind(this);
        this.muxPlayerElement.media.unload = this.onVideoUnload.bind(this);
    }

    private onVideoEnterPip(): void {
        this.isPlayingInPip = true;
    }

    private onVideoExitPip(): void {
        this.isPlayingInPip = false;

        // The video takes one frame to pause.
        setTimeout(() => {
            const isOnDifferentRoute = !this.router.url.includes(this.playbackRoute);
            const backToTabClicked = !this.muxPlayerElement.media.paused;

            if (backToTabClicked && isOnDifferentRoute) {
                this.router.navigateByUrl(this.playbackRoute);
            }

            if (isOnDifferentRoute) {
                this.onVideoUnload();
            }
        }, 0);
    }

    private onVideoUnload(): void {
        if (this.isPlayingInPip || this.hasUnloaded) {
            return;
        }

        (this.muxPlayerElement.media as any).unloadOriginal();
        this.hasUnloaded = true;
    }

    private subscribeToEnterPip(): void {
        if (!this.enterPip) {
            return;
        }

        this.enterPip.pipe(untilDestroyed(this)).subscribe(() => {
            if (!this.isPlayingInPip) {
                this.muxPlayerElement.media.requestPictureInPicture();
            }
        });
    }

    private subscribeToPause(): void {
        if (!this.pause) {
            return;
        }

        this.pause.pipe(untilDestroyed(this)).subscribe(() => {
            this.muxPlayerElement.pause();
        });
    }
}
