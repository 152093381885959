import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ChatDetailActions = createActionGroup({
    source: 'Chat Detail',
    events: {
        Enter: props<{ chatId: string }>(),
        Leave: emptyProps(),
        'Mark Latest Read': props<{ chatId: string }>(),
        'Send Message': props<{ chatId: string; text: string }>()
    }
});
