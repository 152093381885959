<ion-searchbar
    class="chat-list__search ion-no-padding"
    [formControl]="searchInput"
    [placeholder]="'CHAT_LIST_SEARCH_PLACEHOLDER' | translate"
></ion-searchbar>
@if (
    {
        chats: chats$ | async,
        loggedInUser: loggedInUser$ | async,
        initialising: initialising$ | async,
        potentialChats: potentialChats$ | async
    };
    as data
) {
    @if (data.initialising) {
        <cc-loading-spinner></cc-loading-spinner>
    } @else {
        @if (data.chats.length === 0 && data.potentialChats?.length === 0) {
            <span class="chat-list__empty">{{ 'CHAT_NO_CHATS' | translate }}</span>
        } @else {
            <ion-list class="chat-list__list ion-no-padding">
                @for (chat of data.chats; track chat.id) {
                    <cc-chat-list-item
                        [chat]="chat"
                        [hasNewMessage]="hasNewMessage(chat, data.loggedInUser)"
                        [peer]="getPeer(chat, data.loggedInUser.id)"
                        [lastMessageWasSentByMe]="chat.r_last_message.sender === data.loggedInUser.id"
                        (itemClicked)="goToChat(chat.id)"
                    ></cc-chat-list-item>
                }
                @if (data.potentialChats === undefined) {
                    <cc-loading-spinner></cc-loading-spinner>
                }
                @if (data.potentialChats?.length > 0) {
                    <h2 class="chat-list__start-new-header">{{ 'CHAT_POTENTIALS_HEADER' | translate }}</h2>
                    @for (potentialChat of data.potentialChats; track potentialChat.id) {
                        <cc-potential-new-chat
                            [person]="potentialChat"
                            (startNewChat)="createNewChat(potentialChat.id)"
                        ></cc-potential-new-chat>
                    }
                }
            </ion-list>
        }
    }
}
