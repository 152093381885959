import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    getSearchObservable(input: UntypedFormControl, defaultValue?: string): Observable<string> {
        return input.valueChanges.pipe(
            debounceTime(300),
            map((value) => value.trim()),
            distinctUntilChanged(),
            startWith(defaultValue || '')
        );
    }

    getFilteredCollection(collection: any[], searchTerm: string, searchProp: string[]): any[] {
        return !searchTerm ? collection : this.filterItems(collection, searchTerm, searchProp);
    }

    private filterItems(items: any[], searchTerm: string, keys: string[]): any[] {
        return items.filter((item) =>
            keys.some((key) => String(item[key]).toLowerCase().includes(searchTerm.toLowerCase()))
        );
    }
}
