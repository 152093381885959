import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Injectable,
    Injector,
    ViewContainerRef
} from '@angular/core';
import { CountdownTimerComponent } from '@features/html/embeddable-components/countdown-timer/countdown-timer.component';
import { CustomHtmlElementComponent } from '@features/html/embeddable-components/custom-html-element/custom-html-element.component';
import { LinkOverrideComponent } from '@features/html/embeddable-components/link-override/link-override.component';
import { OpenAgendaFilterComponent } from '@features/html/embeddable-components/open-agenda-filter/open-agenda-filter.component';
import { TagComponent } from '@features/html/embeddable-components/tag/tag.component';
import { VideoComponent } from '@features/html/embeddable-components/video/video.component';
import { OpenModuleComponent } from '../../embeddable-components/open-module/open-module.component';
import { BannerAdComponent } from '@shared/banner-ad/components/banner-ad/banner-ad.component';

export const ComponentsToEmbed = [
    OpenModuleComponent,
    CountdownTimerComponent,
    OpenAgendaFilterComponent,
    VideoComponent,
    TagComponent,
    CustomHtmlElementComponent,
    LinkOverrideComponent,
    BannerAdComponent
];

@Injectable()
export class HtmlService {
    componentFactories: ComponentFactory<any>[] = [];

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
        // Create a map that maps selectors to component factories
        this.componentFactories = ComponentsToEmbed.map((component) =>
            this.componentFactoryResolver.resolveComponentFactory<any>(component)
        );
    }

    embedComponents(viewContainerRef: ViewContainerRef, injector: Injector): ComponentRef<any>[] {
        const embeddedComponents = [];
        this.componentFactories.forEach((factory) => {
            // Get all of the elements that match the selector
            const embeddedComponentElements = viewContainerRef.element.nativeElement.querySelectorAll(factory.selector);

            embeddedComponentElements.forEach((element) =>
                embeddedComponents.push(this.embedElement(element, factory, injector, viewContainerRef))
            );
        });
        return embeddedComponents;
    }

    // ! This function can be removed when the supported native apps have
    // ! stopped using the `cc-open-module` directive and only use the component.
    replaceOpenModuleDirectiveWithComponents(
        viewContainerRef: ViewContainerRef,
        injector: Injector
    ): ComponentRef<any>[] {
        const embeddedComponents = [];
        const openModuleSelector = 'cc-open-module';
        const openModuleComponentFactory = this.componentFactories.find(
            (factory) => factory.selector === openModuleSelector
        );
        const openModuleDirectiveElements = viewContainerRef.element.nativeElement.querySelectorAll(
            `[${openModuleSelector}]`
        );

        for (const openModuleDirectiveElement of openModuleDirectiveElements) {
            if (openModuleDirectiveElement.tagName === openModuleSelector.toUpperCase()) {
                continue;
            }

            const moduleId = openModuleDirectiveElement.getAttribute(openModuleSelector);
            openModuleDirectiveElement.setAttribute('id', moduleId);

            embeddedComponents.push(
                this.embedElement(openModuleDirectiveElement, openModuleComponentFactory, injector, viewContainerRef)
            );
        }
        return embeddedComponents;
    }

    destroyComponents(embeddedComponents: ComponentRef<any>[]): void {
        // Destroy injected components
        embeddedComponents.forEach((component) => component.destroy());
    }

    private embedElement(
        targetElement: Element,
        componentFactory: ComponentFactory<any>,
        injector: Injector,
        directiveViewContainer: ViewContainerRef
    ): ComponentRef<any> {
        // Get the child nodes of the custom element
        const projectableNodes = [Array.from(targetElement.childNodes)];

        // Create a component in the directive view container
        const embeddedComponent = directiveViewContainer.createComponent(
            componentFactory,
            undefined,
            injector,
            projectableNodes
        );
        const embeddedElement: HTMLElement = embeddedComponent.location.nativeElement;

        // Put the attributes back onto the component
        for (const attr of (targetElement as any).attributes) {
            embeddedComponent.instance[attr.nodeName] = attr.nodeValue;
            embeddedElement.setAttribute(attr.nodeName, attr.nodeValue);
        }

        // Replace the target element with the newly created component
        targetElement.replaceWith(embeddedElement);

        return embeddedComponent;
    }
}
