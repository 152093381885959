import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ChatPopupActions } from '@features/chat/store/actions/chat-popup.actions';
import { ChatPopupService } from '@features/chat/services/chat-popup/chat-popup.service';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { AppCoreActions } from '@core/root-store/store/app-core/actions/app-core.actions';
import { ChatStorageService } from '@features/chat/services/chat-storage/chat-storage.service';
import { ChatListActions } from '@features/chat/store/actions/chat-list.actions';

@Injectable()
export class ChatPopupEffects {
    open$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChatPopupActions.open),
                tap(() => this.chatPopupService.open())
            ),
        { dispatch: false }
    );

    close$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ChatPopupActions.close),
                tap(() => this.chatPopupService.close())
            ),
        { dispatch: false }
    );

    openOnPageLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppCoreActions.initialiseAppSuccess),
            withLatestFrom(
                this.chatPopupService.usePopup(),
                this.chatStorageService.shouldUsePopup(),
                this.chatStorageService.getPreviousChat()
            ),
            filter(([, usePopup, usePopupFromStorage]) => usePopup && usePopupFromStorage),
            map(([, , , previousChat]) => {
                if (!previousChat) {
                    return ChatPopupActions.open();
                }
                return ChatListActions.goToChat({ chatId: previousChat });
            })
        )
    );

    constructor(
        private actions$: Actions,
        private chatPopupService: ChatPopupService,
        private chatStorageService: ChatStorageService
    ) {}
}
