import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InlineChat, InlineChatPeer } from '@features/chat/interfaces/inline-chat';

@Component({
    selector: 'cc-chat-list-item',
    templateUrl: './chat-list-item.component.html',
    styleUrls: ['./chat-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatListItemComponent {
    @Input()
    public chat: InlineChat;

    @Input()
    public peer: InlineChatPeer;

    @Input()
    public hasNewMessage: boolean;

    @Input()
    public lastMessageWasSentByMe: boolean;

    @Output()
    public itemClicked: EventEmitter<void> = new EventEmitter<void>();

    public onItemClicked(): void {
        this.itemClicked.emit();
    }
}
