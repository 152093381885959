import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { ChatPopupComponent } from '@features/chat/smart-components/chat-popup/chat-popup.component';
import { PopoverController } from '@ionic/angular';
import { Breakpoints, BreakpointService } from '@shared/utilities/breakpoint.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ChatPopupService {
    public popover: HTMLIonPopoverElement;
    private anchor: HTMLElement;

    constructor(
        private popoverController: PopoverController,
        private appCoreFacadeService: AppCoreFacadeService,
        private breakpointService: BreakpointService
    ) {}

    public async open(): Promise<void> {
        if (this.popover) {
            return;
        }
        const event: any = { target: this.anchor };
        this.popover = await this.popoverController.create({
            event,
            component: ChatPopupComponent,
            showBackdrop: false,
            backdropDismiss: false,
            cssClass: 'chat-popup-popover'
        });
        this.popover.present();
    }

    public async close(): Promise<void> {
        this.popover?.dismiss();
        this.popover = null;
    }

    public usePopup(): Observable<boolean> {
        return combineLatest([
            this.breakpointService.is(({ width }) => width > Breakpoints.Medium),
            this.appCoreFacadeService.getAppSettings().pipe(map((app) => app?.can_chat))
        ]).pipe(map(([largeDevice, chatEnabled]) => chatEnabled && largeDevice));
    }

    public registerAnchor(element: HTMLElement): void {
        this.anchor = element;
    }
}
