import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ChatFacadeService } from '@features/chat/services/chat-facade.service';
import { Observable } from 'rxjs';
import { ChatPopupView } from '@features/chat/enums/chat-popup-view.enum';
import { ChatPopupActions } from '@features/chat/store/actions/chat-popup.actions';
import { People } from '@api';
import { PersonActionService } from '@features/people/services/person-action/person-action.service';
import { switchMap } from 'rxjs/operators';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { ChatStorageService } from '@features/chat/services/chat-storage/chat-storage.service';

@Component({
    selector: 'cc-chat-popup',
    templateUrl: './chat-popup.component.html',
    styleUrls: ['./chat-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatPopupComponent implements OnInit, OnDestroy {
    view$: Observable<ChatPopupView>;
    chatId$: Observable<string | null>;
    detailPeer$: Observable<People | null>;
    canVideoCall$: Observable<boolean>;
    appUrl$: Observable<string>;

    ChatPopupView = ChatPopupView;

    constructor(
        private chatFacadeService: ChatFacadeService,
        private personActionService: PersonActionService,
        private appCoreFacadeService: AppCoreFacadeService,
        private chatStorageService: ChatStorageService
    ) {}

    public ngOnInit(): void {
        this.view$ = this.chatFacadeService.getPopupView();
        this.chatId$ = this.chatFacadeService.getPopupChatId();
        this.detailPeer$ = this.chatFacadeService.getDetailPeer();

        this.canVideoCall$ = this.detailPeer$.pipe(switchMap((peer) => this.personActionService.canVideoCall(peer)));
        this.appUrl$ = this.appCoreFacadeService.getAppName();

        this.chatStorageService.setShouldUsePopup(true);
    }

    public enterList(): void {
        this.chatFacadeService.dispatch(ChatPopupActions.enterList());
    }

    public videoCall(peer: People): void {
        this.personActionService.videoCall(peer);
    }

    public close(): void {
        this.chatFacadeService.dispatch(ChatPopupActions.close());
    }

    public ngOnDestroy(): void {
        this.chatStorageService.setShouldUsePopup(false);
    }
}
