import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ChatPopupActions = createActionGroup({
    source: 'Chat Popup',
    events: {
        'Enter List': emptyProps(),
        'Enter Detail': props<{ chatId: string }>(),
        Open: emptyProps(),
        Close: emptyProps()
    }
});
