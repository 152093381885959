import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { InlineChat, InlineChatPeer } from '@features/chat/interfaces/inline-chat';
import { People, SlimPeople } from '@api';
import { UntypedFormControl } from '@angular/forms';
import { ChatFacadeService } from '@features/chat/services/chat-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { SearchService } from '@shared/search/services/search.service';
import { ChatListActions } from '@features/chat/store/actions/chat-list.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChatActions } from '@features/chat/store/actions/chat.actions';

@Component({
    selector: 'cc-chat-list',
    templateUrl: './chat-list.component.html',
    styleUrls: ['./chat-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class ChatListComponent implements OnInit {
    public chats$: Observable<InlineChat[]>;
    public loggedInUser$: Observable<People>;
    public initialising$: Observable<boolean>;
    public potentialChats$: Observable<SlimPeople[]>;
    public searchInput = new UntypedFormControl();

    constructor(
        private chatFacadeService: ChatFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private searchService: SearchService
    ) {}

    public ngOnInit(): void {
        this.chatFacadeService.dispatch(ChatListActions.enterListPage());
        this.chats$ = this.chatFacadeService.getFilteredChats();
        this.loggedInUser$ = this.authenticationFacadeService.getAuthenticatedPerson();
        this.initialising$ = this.chatFacadeService.getListInitialising();
        this.potentialChats$ = this.chatFacadeService.getPotentialChats();

        const searchTerm$ = this.searchService.getSearchObservable(this.searchInput).pipe(untilDestroyed(this));
        searchTerm$.subscribe((searchTerm: string) => {
            this.chatFacadeService.dispatch(ChatListActions.submitSearch({ searchTerm }));
        });
    }

    public goToChat(chatId: string): void {
        this.chatFacadeService.dispatch(
            ChatListActions.goToChat({
                chatId
            })
        );
    }

    public hasNewMessage(chat: InlineChat, loggedInUser: People): boolean {
        return !chat.r_last_message.read_by.includes(loggedInUser.id);
    }

    public getPeer(chat: InlineChat, loggedInUser: number): InlineChatPeer {
        return chat.peers.find((p) => p.id !== loggedInUser);
    }

    public createNewChat(personId: number): void {
        this.chatFacadeService.dispatch(ChatActions.openChat({ personId }));
    }
}
