export interface FeedPost {
    id?: number;
    likes?: number[];
    uuid?: string;
    text?: string;
    created?: Date;
    is_active?: boolean;
    /**
     * Moderation Status of the Feed Post, options are `pending` and `accepted`.  Defaults to `pending`
     */
    moderation?: FeedPost.ModerationEnum;
    is_visible?: boolean;
    module: number;
    author?: number;
    photo?: string;
    author_inlined?: InlineAuthor;
    is_pinned: boolean;
    pinned_at: string;
}

export interface InlineAuthor {
    app_picture: string;
    first_name: string;
    last_name: string;
    pronouns: string;
    id: number;
}

export namespace FeedPost {
    export enum ModerationEnum {
        Pending = 'pending',
        Accepted = 'accepted'
    }
}
