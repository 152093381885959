import { NgModule } from '@angular/core';
import { AppCommonModule } from '@common/common.module';
import { SharedPeopleModule } from '@shared/people/shared-people.module';
import { SearchModule } from '@shared/search/search.module';
import { PeopleModule } from '../people/people.module';
import { ChatListItemComponent } from './components/chat-list-item/chat-list-item.component';
import { ChatStoreModule } from './store/chat-store.module';
import { UserChatRoutingModule } from './user-chat-module-routing.module';
import { PotentialNewChatComponent } from '@features/chat/components/potential-new-chat/potential-new-chat.component';
import { ChatListComponent } from '@features/chat/smart-components/chat-list/chat-list.component';

@NgModule({
    declarations: [ChatListItemComponent, ChatListComponent, PotentialNewChatComponent],
    exports: [ChatListComponent],
    imports: [AppCommonModule, UserChatRoutingModule, ChatStoreModule, PeopleModule, SharedPeopleModule, SearchModule]
})
export class UserChatModule {}
