import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { AppState } from '../../root-store/store/app-state.model';
import { Observable } from 'rxjs';
import { AppCoreSelectors } from '../../root-store/store/app-core/selectors/app-core.selectors';
import { App } from '@shared/api';

@Injectable({
    providedIn: 'root'
})
export class AppCoreFacadeService {
    constructor(private store: Store<AppState>) {}

    getOneSignalUserId(): Observable<string> {
        return this.store.select(AppCoreSelectors.selectOneSignalUserId);
    }

    getAppInitialising(): Observable<boolean> {
        return this.store.select(AppCoreSelectors.selectAppInitialising);
    }

    getAppName(): Observable<string> {
        return this.store.select(AppCoreSelectors.selectAppName);
    }

    getAppSettings(): Observable<App> {
        return this.store.select(AppCoreSelectors.selectAppSettings);
    }

    dispatch(action: Action): void {
        return this.store.dispatch(action);
    }
}
