import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompaniesState } from '../models/companies-module-state.model';
import { companiesStoreKey } from '../models/companies-app-state.model';
import { CompanyDetailFieldDisplay } from '@features/companies/models/company-detail-field-display';
import { CompanyDetailActionButtonType } from '@features/companies/models/company-detail-action-button';
import { convertCustomValuesToFields } from '@shared/custom-fields/functions/custom-value.functions';
import { AuthenticationSelectors } from '@core/root-store/store/authentication/selectors/authentication.selectors';
import { ActionPopoverType } from '@shared/action-popover/enums/action-popover-type.enum';

const selectCompanyState = createFeatureSelector<CompaniesState>(companiesStoreKey);
const selectCompanyDetailPage = createSelector(selectCompanyState, (state) => state.companyDetailPage);

const selectInitialising = createSelector(selectCompanyDetailPage, (state) => state.initialising);
const selectCompany = createSelector(selectCompanyDetailPage, (state) => state.company);
const selectFieldDisplay = createSelector(
    selectCompanyDetailPage,
    AuthenticationSelectors.selectAuthenticatedPerson,
    (state, loggedInUser) => {
        if (state.initialising) {
            return;
        }
        const shouldDisplay = (field: string) => {
            const fieldIsArray = Array.isArray(state.company[field]);
            return !!(
                state.companyFieldVisibility[field] &&
                state.company[field] &&
                (!fieldIsArray || state.company[field].length > 0)
            );
        };
        return {
            logo: shouldDisplay('logo'),
            name: shouldDisplay('company_name'),
            about: shouldDisplay('about'),
            location: shouldDisplay('location'),
            address: shouldDisplay('address'),
            phoneNumber: shouldDisplay('phone_number'),
            email: shouldDisplay('email'),
            website: shouldDisplay('website'),
            representatives: shouldDisplay('representatives'),
            mainRepresentativeChat:
                !!state.company.main_representative &&
                !!loggedInUser &&
                state.company.main_representative !== loggedInUser.id &&
                loggedInUser.chat_enabled,
            groups: shouldDisplay('groups')
        } as CompanyDetailFieldDisplay;
    }
);
const selectActionButtons = createSelector(selectCompany, selectFieldDisplay, (company, fieldDisplay) => {
    if (!fieldDisplay) {
        return [];
    }
    return [
        ...(fieldDisplay.email
            ? [
                  {
                      type: CompanyDetailActionButtonType.email,
                      text: 'COMPANIES_COMPANY_BUTTONS_EMAIL',
                      icon: 'email',
                      popover: ActionPopoverType.Email,
                      popoverContent: company.email
                  }
              ]
            : []),
        ...(fieldDisplay.mainRepresentativeChat
            ? [
                  {
                      type: CompanyDetailActionButtonType.mainRepresentativeChat,
                      text: 'COMPANIES_COMPANY_BUTTONS_CHAT',
                      icon: 'message'
                  }
              ]
            : []),
        ...(fieldDisplay.website
            ? [
                  {
                      type: CompanyDetailActionButtonType.website,
                      text: 'COMPANIES_COMPANY_BUTTONS_WEBSITE',
                      icon: 'language',
                      popover: ActionPopoverType.Website,
                      popoverContent: company.website
                  }
              ]
            : []),
        ...(fieldDisplay.phoneNumber
            ? [
                  {
                      type: CompanyDetailActionButtonType.phoneNumber,
                      text: 'COMPANIES_COMPANY_BUTTONS_PHONE_NUMBER',
                      icon: 'phone',
                      popover: ActionPopoverType.Phone,
                      popoverContent: company.phone_number
                  }
              ]
            : [])
    ];
});
const selectStripStyle = createSelector(selectCompanyDetailPage, (state) => {
    if (state.initialising) {
        return {};
    }
    if (state.company.background_header) {
        return { 'background-image': `url(${state.company.background_header})` };
    }
    return { 'background-color': 'var(--cc-primary-color)' };
});
const selectLocations = createSelector(selectCompanyDetailPage, (state) => {
    if (state.initialising) {
        return;
    }
    return state.company.location;
});
const selectSocialInfo = createSelector(selectCompanyDetailPage, (state) => {
    if (state.initialising) {
        return;
    }
    return state.company.socialinfo.filter((socialInfo) => !!state.companyFieldVisibility[socialInfo.site]);
});
const selectCustomFields = createSelector(selectCompanyDetailPage, (state) => {
    if (state.initialising) {
        return;
    }
    const customFields = convertCustomValuesToFields(state.company.custom_values, false);
    const filterEmptyFields = (field) =>
        (field.childValue && field.childValue.value) || (field.childFields && field.childFields.length > 0);
    return customFields.filter(filterEmptyFields);
});

const selectTitle = createSelector(selectCompanyDetailPage, (state) => {
    return state.company?.company_name;
});

export const CompanyDetailPageSelectors = {
    initialising: selectInitialising,
    company: selectCompany,
    fieldDisplay: selectFieldDisplay,
    actionButtons: selectActionButtons,
    stripStyle: selectStripStyle,
    locations: selectLocations,
    socialInfo: selectSocialInfo,
    customFields: selectCustomFields,
    title: selectTitle
};
