import { TimezoneMode } from '@core/locale/services/selected-timezone/selected-timezone.service';
import * as models from '@shared/api';
import { AppTag } from '@shared/tags/interfaces/app-tag.interface';

export interface People extends NotificationSettings {
    accepts_video_calls?: boolean;
    app_picture?: string;
    show_email?: boolean;
    can_receive_chat_messages?: boolean;
    chat_enabled?: boolean;
    phone?: string;
    app: string;
    challenge_score?: number;
    /**
     * Alias used during import operations for admins to easily identify people
     */
    alias?: string;
    created?: Date;
    id?: number;
    website?: string;
    show_phone?: boolean;
    exclude_from_adoption_rates?: boolean;
    completed_challenges?: Array<models.PeopleCompletedChallenge>;
    location?: string;
    documents?: Array<number>;
    title?: string;
    last_chat_reminder_sent?: Date;
    about?: string;
    is_active?: boolean;
    address?: string;
    meetings_can_be_invited?: boolean;
    uuid?: string;
    peoplegroups?: string[];
    receive_chat_emails?: boolean;
    challenges_enabled?: boolean;
    show_first_name?: boolean;
    job_title?: string;
    show_last_name?: boolean;
    eventbrite_id?: string;
    last_name?: string;
    deletion_requested?: Date;
    first_name?: string;
    picture?: number;
    meetings_can_invite?: boolean;
    last_updated?: Date;
    user?: string;
    company?: string;
    /**
     * The email for this person, if the user registers later this will be used to link that user
     */
    email: string;
    archived_alerts?: number[];
    personal_agenda?: number[];
    pronouns?: string;
    read_alerts?: number[];
    device_ids?: string[];
    show_in_people_modules: boolean;
    video_calls_enabled: boolean;
    mute_feedposts_from: number[];
    badge_code?: string;
    meeting_booking_enabled?: boolean;
    meeting_booking_allowed?: boolean;
    business_cards_enabled?: boolean;
    can_exchange_business_cards?: boolean;
    attending_in_person?: boolean;
    waiting_list_agendas: Array<number>;
    // Only here when viewing own user
    is_staff?: boolean;
    appear_offline?: boolean;
    timezone_preference: TimezoneMode;
    tags: AppTag[];
    user_tags: AppTag[];
}

export enum NotificationSettingsPreference {
    noNotifications = 'no_notifications',
    silentNotifications = 'silent_notifications',
    soundNotifications = 'sound_notifications'
}

export interface NotificationSettings {
    in_app_notification_preference: NotificationSettingsPreference;
    receive_automated_emails: boolean;
}
