import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { initializeAuth, inMemoryPersistence, provideAuth } from '@angular/fire/auth';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideDatabase(() => getDatabase()),
        provideAuth(() => {
            const app = getApp();
            const auth = initializeAuth(app, {
                persistence: inMemoryPersistence
            });
            return auth;
        })
    ]
})
export class FirebaseModule {}
