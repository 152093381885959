<ng-container *ngIf="customFields && customFields.length">
    <ng-container *ngFor="let customField of customFields">
        <ion-card
            *ngIf="
                (customField.childValue && customField.childValue.value) ||
                (customField.childFields && customField.childFields.length)
            "
            class="custom-field-card"
            [ngStyle]="{ 'background-color': customField.bg_color || 'auto', color: customField.text_color || 'auto' }"
        >
            <ion-card-header>
                <ion-card-title class="bold custom-field__title-container">
                    <div>
                        <span [ngStyle]="{ color: customField.text_color || 'auto' }">{{ customField.key }}</span>
                        <div *ngIf="customField.tooltip" class="custom-field__tooltip">
                            <div class="custom-field__tooltip-container">
                                <ion-button
                                    *ngIf="customField.tooltip"
                                    class="ion-hide-sm-down tooltip-btn"
                                    fill="clear"
                                    placement="left"
                                    [ngStyle]="{ color: customField.text_color }"
                                    [tooltip]="customField.tooltip"
                                >
                                    <i class="material-icons">info</i>
                                </ion-button>
                            </div>
                        </div>
                    </div>
                    <ion-item-divider
                        *ngIf="customField.childFields && customField.childFields.length"
                        [ngStyle]="{ 'border-color': customField.text_color }"
                    ></ion-item-divider>
                </ion-card-title>
            </ion-card-header>
            <ng-container *ngIf="customField.childValue">
                <ion-card-content>
                    <div
                        *ngIf="customField.data_type !== 'url'"
                        class="wysiwyg-content"
                        [ccCompileHtml]="customField.childValue.value"
                    ></div>
                    <a
                        *ngIf="customField.data_type === 'url'"
                        target="_system"
                        [innerHtml]="customField.childValue.value"
                        [href]="customField.childValue.value"
                    ></a>
                </ion-card-content>
            </ng-container>
            <ng-container *ngIf="customField.childFields">
                <ng-container *ngFor="let childField of customField.childFields">
                    <ion-card-content *ngIf="childField.childValue" class="child-fields">
                        <ng-container [ngSwitch]="childField.data_type">
                            <div
                                *ngSwitchCase="'paragraph'"
                                class="wysiwyg-content"
                                [ccCompileHtml]="childField.childValue.value"
                            ></div>
                            <p *ngSwitchCase="'text'">
                                <strong [innerHtml]="childField.key + ': '"></strong>
                                <span [innerHtml]="childField.childValue.value"></span>
                            </p>
                            <span *ngSwitchCase="'url'">
                                <strong [innerHtml]="childField.key + ': '"></strong>
                                <a
                                    [href]="childField.childValue.value"
                                    target="_system"
                                    [innerHtml]="childField.childValue.value"
                                ></a>
                            </span>
                        </ng-container>
                    </ion-card-content>
                </ng-container>
            </ng-container>
        </ion-card>
    </ng-container>
</ng-container>
