import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';
import { IonContent } from '@ionic/angular';
import { delay, filter } from 'rxjs/operators';

@Component({
    selector: 'cc-auto-scroll-bottom',
    templateUrl: './auto-scroll-bottom.component.html',
    styleUrls: ['./auto-scroll-bottom.component.scss']
})
export class AutoScrollBottomComponent<T> implements AfterViewInit, AfterViewChecked {
    @ViewChild('ionContent')
    public ionContent: IonContent;

    @ViewChild('scrollContainer')
    public scrollContainer: ElementRef<HTMLDivElement>;

    @Input()
    public items: QueryList<T>;

    @Input()
    public threshold = 100;

    @Input()
    public useIonContent = true;

    @Input()
    public shouldScroll = true;

    @Output()
    public scrollToBottomClicked: EventEmitter<void> = new EventEmitter<void>();

    public scrollOnNewItems = true;
    public listeningToChanges = false;
    public scrollElement?: HTMLElement;

    public ngAfterViewInit(): void {
        this.setupScrollElement();
    }

    public async setupScrollElement(): Promise<void> {
        if (this.useIonContent) {
            this.scrollElement = await this.ionContent.getScrollElement();
        } else {
            this.scrollElement = this.scrollContainer.nativeElement;
        }

        /* Handles first scroll */
        this.scrollToBottom();
    }

    public ngAfterViewChecked(): void {
        /* Keep checking until the query list is defined, and then start listening */
        if (this.listeningToChanges || !this.items) {
            return;
        }
        this.keepChatScrolledToBottom();
        this.listeningToChanges = true;
    }

    public onScrollToBottomClicked(): void {
        this.scrollToBottomClicked.emit();
        this.scrollToBottom();
    }

    public scrollToBottom(): void {
        if (!this.useIonContent) {
            this.scrollElement.scrollTop = this.scrollElement.scrollHeight;
        } else if (this.ionContent instanceof IonContent) {
            this.ionContent.scrollToBottom();
        }
    }

    public keepChatScrolledToBottom(): void {
        /* Delay is added so that the message appears in the DOM before scrolling */
        this.items.changes
            .pipe(
                delay(0),
                filter(() => this.scrollOnNewItems && this.shouldScroll)
            )
            .subscribe(() => {
                this.scrollToBottom();
            });
    }

    public onScroll(): void {
        if (!this.scrollElement) {
            return;
        }
        const position = this.scrollElement.scrollTop + this.scrollElement.offsetHeight;
        const height = this.scrollElement.scrollHeight;
        this.scrollOnNewItems = position > height - this.threshold;
    }

    public onResize(): void {
        if (!this.scrollOnNewItems) {
            return;
        }

        this.scrollToBottom();
    }
}
