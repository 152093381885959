import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FeedModule } from '@shared/api';
import { PaginatedResponse } from '../../../../shared/pagination/models/paginated-response.model';
import { ActivityFeedView } from '../../models/activity-feed-current-view.enum';
import { PostComment } from '../../models/post-comment.model';
import { Post } from '../../models/post.model';

export const ActivityFeedApiActions = createActionGroup({
    source: 'Activity Feed Api',
    events: {
        'Get Activity Feed Page Data Failure': props<{ error: HttpErrorResponse }>(),
        'Get Activity Feed Page Data Success': props<{
            posts: Post[];
            module: FeedModule;
            scrollBottomDisabled: boolean;
        }>(),
        'Submit Post Success': props<{ moduleId: number }>(),
        'Submit Post Failure': props<{ error: HttpErrorResponse }>(),
        'Edit Post Success': props<{ postId: number; updatedPost: Partial<Post> }>(),
        'Edit Post Failure': props<{ error: HttpErrorResponse }>(),
        'Post Toggle Like Success': props<{ view: ActivityFeedView; postId: number; userId: number }>(),
        'Post Toggle Like Failure': props<{ view: ActivityFeedView; error: HttpErrorResponse; postId: number }>(),
        'Comment Toggle Like Success': props<{
            commentId: number;
            userId: number;
            view: ActivityFeedView;
            postId: number;
        }>(),
        'Comment Toggle Like Failure': props<{
            error: HttpErrorResponse;
            commentId: number;
            view: ActivityFeedView;
            postId: number;
        }>(),
        'Submit Comment Success': props<{
            postId: number;
            comment: PostComment;
            view: ActivityFeedView;
        }>(),
        'Submit Comment Failure': props<{
            postId: number;
            error: HttpErrorResponse;
            view: ActivityFeedView;
        }>(),

        'Comment Visibility Change Success': props<{
            postId: number;
            commentId: number;
            isVisible: boolean;
            view: ActivityFeedView;
        }>(),
        'Comment Visibility Change Failure': props<{ error: HttpErrorResponse }>(),
        'Delete Post Success': props<{ moduleId: number; postId: number }>(),
        'Delete Post Failure': props<{ error: HttpErrorResponse }>(),
        'Upload Image Success': props<{ imageUrl: string }>(),
        'Upload Image Failure': props<{ error: HttpErrorResponse }>(),
        'Upload Video Started': emptyProps(),
        'Upload Video Progress': props<{ progress: number }>(),
        'Upload Video Cancelled': emptyProps(),
        'Upload Video Success': props<{ videoId: string }>(),
        'Report Post Success': props<{ postId: number }>(),
        'Report Post Failure': props<{ error: HttpErrorResponse }>(),
        'Hide Author Posts Success': emptyProps(),
        'Hide Author Posts Failure': props<{ error: HttpErrorResponse }>(),
        'Reply To Comment Success': props<{ postId: number; reply: PostComment; view: ActivityFeedView }>(),
        'Reply To Comment Failure': props<{ error: HttpErrorResponse }>(),
        'Fetch Pagination Success': props<{ page: number; paginatedResponse: PaginatedResponse<Post> }>(),
        'Fetch Pagination Failure': props<{ error: HttpErrorResponse }>(),
        'Fetch Post Page Success': props<{ post: Post; module: FeedModule }>(),
        'Fetch Post Page Failure': props<{ error: HttpErrorResponse }>(),
        'Delete Comment Success': props<{ commentId: number }>(),
        'Delete Comment Failure': props<{ error: HttpErrorResponse }>()
    }
});
