import { ComponentRef, Directive, Injector, Input, OnChanges, OnDestroy, ViewContainerRef } from '@angular/core';
import { HtmlService } from '@features/html/services/html/html.service';

@Directive({
    selector: '[ccCompileHtml]'
})
/*
WARNING: Change detection will only work in components compiled from this directive,
if the component you're using is set to use the default change detection (not on push)
*/
export class CompileHtmlDirective implements OnChanges, OnDestroy {
    @Input('ccCompileHtml')
    public html: string;

    // List of embedded components which must be cleaned up
    private embeddedComponents: ComponentRef<any>[] = [];

    constructor(
        private viewContainerRef: ViewContainerRef,
        private htmlService: HtmlService,
        private injector: Injector
    ) {}

    public ngOnChanges(): void {
        this.cleanupEmbeddedComponents();

        this.viewContainerRef.element.nativeElement.innerHTML = this.html;
        this.embeddedComponents = [
            ...this.htmlService.embedComponents(this.viewContainerRef, this.injector),
            ...this.htmlService.replaceOpenModuleDirectiveWithComponents(this.viewContainerRef, this.injector)
        ];
    }

    public ngOnDestroy(): void {
        this.cleanupEmbeddedComponents();
    }

    private cleanupEmbeddedComponents(): void {
        this.htmlService.destroyComponents(this.embeddedComponents);
    }
}
