import { InlineAuthor } from '@shared/api';

export interface FeedPostComment {
    id?: number;
    likes?: number[];
    /**
     * Moderation Status of the Feed Post, options are `pending` and `accepted`.  Defaults to `pending`
     */
    moderation?: FeedPostComment.ModerationEnum;
    text?: string;
    created?: Date;
    is_active?: boolean;
    is_visible?: boolean;
    feed_post: number;
    author?: number;
    author_inline: InlineAuthor;
    parent_comment: number;
}

export namespace FeedPostComment {
    export enum ModerationEnum {
        Pending = 'pending',
        Accepted = 'accepted'
    }
}
