import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatState, chatStoreKey } from '../models/chat.state.model';
import { People } from '@shared/api';
import { filterBySearchTerm } from '../../../../shared/utilities/sorting-utility.functions';
import { InlineChat } from '../../interfaces/inline-chat';

const selectChatState = createFeatureSelector<ChatState>(chatStoreKey);

const selectSearchedChats = (loggedInUser: People) =>
    createSelector(selectChatState, (state) => {
        if (!loggedInUser || !state.chats || !state.list) {
            return [];
        }

        const searchedChats = filterBySearchTerm<InlineChat>(
            state.chats.filter(
                (chat) => chat.r_last_message && !!chat.peers.find((peer) => peer.id === loggedInUser.id)
            ),
            state.list.searchTerm,
            ['first_name', 'last_name'],
            (chat) => chat.peers.find((p) => p.id !== loggedInUser.id)
        );
        return searchedChats.sort(
            (c1, c2) => new Date(c2.r_last_message.created).getTime() - new Date(c1.r_last_message.created).getTime()
        );
    });

const selectPotentialChats = (loggedInUser: People) =>
    createSelector(selectChatState, (state) => {
        if (!loggedInUser || !state.chats || !state.list) {
            return [];
        }
        return state.list.potentialChats?.filter((person) => {
            if (person.id === loggedInUser.id) {
                return false;
            }

            const existingChat = state.chats.find((chat) => chat.peers.some((peer) => peer.id === person.id));
            // Don't show people that you've already chatted to, unless the existing chat has no last message (i.e. it's a new chat)
            return !existingChat || !existingChat.r_last_message;
        });
    });

const selectBadgeNumber = (loggedInUser: People) =>
    createSelector(selectChatState, (state) => {
        if (!loggedInUser) {
            return 0;
        }
        return state.chats.filter(
            (chat) => chat.r_last_message && chat.r_last_message.read_by.indexOf(loggedInUser.id) === -1
        ).length;
    });

const selectChatPagePeer = createSelector(selectChatState, (state) => {
    if (!state.detail) {
        return;
    }
    return state.detail.peer;
});

const selectChatPageSendingInProgress = createSelector(selectChatState, (state) => {
    if (!state.detail) {
        return;
    }
    return state.detail.sendingInProgress;
});

const selectPopupView = createSelector(selectChatState, (state) => state.popup.view);
const selectPopupChatId = createSelector(selectChatState, (state) => state.popup.chatId);
const selectPopupOpen = createSelector(selectChatState, (state) => state.popup.open);

export const ChatSelectors = {
    chats: createSelector(selectChatState, (state) => state.chats),
    badgeNumber: selectBadgeNumber,
    messages: (chatId: string) => createSelector(selectChatState, (state) => state.messages[chatId]),
    list: {
        initialising: createSelector(selectChatState, (state) => state.initialising),
        searchedChats: selectSearchedChats,
        potentialChats: selectPotentialChats
    },
    detail: {
        peer: selectChatPagePeer,
        initialising: createSelector(selectChatState, (state) => state.initialising || state.detail?.initialising),
        sendingInProgress: selectChatPageSendingInProgress
    },
    popup: {
        view: selectPopupView,
        chatId: selectPopupChatId,
        open: selectPopupOpen
    }
};
