import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Video } from '@api/models/Video';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { LoginPromptService } from '@features/login/services/login-prompt.service';
import { VideoApiService } from '@features/video/services/video-api.service';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { IntersectionStatus } from '@shared/utilities/from-intersection-observer';
import { PlaybackTokens } from '@shared/mux/interfaces/playback-tokens.interface';

@Component({
    selector: 'cc-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
    @Input()
    videoid: string;

    loggedIn$: Observable<boolean>;

    video$: Observable<Video>;
    playbackTokens$: Observable<PlaybackTokens>;
    posterStyle: Record<string, string> | null;
    error: string;

    step$ = new BehaviorSubject<VideoStep>(VideoStep.Idle);
    videoStepEnum = VideoStep;

    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private videoApiService: VideoApiService,
        private loginPromptService: LoginPromptService
    ) {}

    ngOnInit(): void {
        this.loggedIn$ = this.authenticationFacadeService.isAuthenticated();
    }

    fetchVideo(): void {
        this.step$.next(VideoStep.FetchingVideo);

        this.video$ = this.appCoreFacadeService.getAppName().pipe(
            switchMap((appUrl) => this.videoApiService.getVideo(appUrl, this.videoid)),
            tap((video) => {
                this.step$.next(VideoStep.WaitingForPlay);
                this.posterStyle = this.getPosterStyle(video);
            }),
            catchError((error: HttpErrorResponse) => this.handleError(error.statusText))
        );
    }

    play(step: VideoStep, loggedIn: boolean, event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();

        if (step !== VideoStep.WaitingForPlay) {
            return;
        }

        if (!loggedIn) {
            this.loginPromptService.showPrompt('VIDEO_LOGIN_TO_WATCH');
            return;
        }

        this.step$.next(VideoStep.FetchingPlaybackInfo);

        this.playbackTokens$ = this.fetchPlaybackTokens$();
    }

    fetchPlaybackTokens$(): Observable<PlaybackTokens> {
        return this.appCoreFacadeService.getAppName().pipe(
            switchMap((appUrl) => forkJoin([of(appUrl), this.videoApiService.getPlaybackIds(appUrl, this.videoid)])),
            tap(([, playbackIds]) => {
                if (playbackIds.length === 0 || !playbackIds[0].playback_id) {
                    throw new Error('Playback ids not found');
                }
            }),
            switchMap(([appUrl, playbackIds]) =>
                this.videoApiService.getPlaybackTokens(appUrl, this.videoid, playbackIds[0].playback_id)
            ),
            tap(() => this.step$.next(VideoStep.Ready)),
            catchError((error: HttpErrorResponse | Error) =>
                this.handleError(error instanceof HttpErrorResponse ? error.statusText : error.message)
            )
        );
    }

    onVisibilityChange(status: IntersectionStatus): void {
        if (status === IntersectionStatus.Visible && this.step$.value === VideoStep.Idle) {
            this.fetchVideo();
        }
    }

    handleError(error: string): Observable<any> {
        this.step$.next(VideoStep.Error);
        this.error = error;
        return of(undefined);
    }

    retry(loggedIn: boolean, event: MouseEvent): void {
        this.step$.next(VideoStep.WaitingForPlay);
        this.play(this.step$.value, loggedIn, event);
    }

    private getPosterStyle(video: Video): Record<string, string> | null {
        if (!video.poster && !video.thumbnail) {
            return;
        }
        const imageUrl = video.poster || video.thumbnail;
        return { 'background-image': `url(${imageUrl})` };
    }
}

enum VideoStep {
    Idle,
    FetchingVideo,
    WaitingForPlay,
    FetchingPlaybackInfo,
    Ready,
    Error
}
