import { AppState } from '../../../../core/root-store/store/app-state.model';
import { ChatMessage, People, SlimPeople } from '@shared/api';
import { InlineChat } from '../../interfaces/inline-chat';
import { ChatPopupView } from '@features/chat/enums/chat-popup-view.enum';

export const chatStoreKey = 'chat';

export interface ChatState {
    initialising: boolean;
    chats: InlineChat[];
    detail: ChatDetailState;
    list: ChatListState;
    messages: { [chatId: string]: ChatMessage[] };
    popup: ChatPopupState;
}

export interface ChatListState {
    searchTerm: string;
    potentialChats: SlimPeople[] | undefined;
}

export interface ChatDetailState {
    chatId: string;
    initialising: boolean;
    sendingInProgress: boolean;
    peer: People;
}

export interface ChatPopupState {
    open: boolean;
    view: ChatPopupView;
    chatId?: string;
}

export interface ChatAppState extends AppState {
    [chatStoreKey]: ChatState;
}
