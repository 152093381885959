import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { ChatAppState } from '../store/models/chat.state.model';
import { ChatSelectors } from '../store/selectors/chat.selectors';
import { Observable } from 'rxjs';
import { ChatMessage, People, SlimPeople } from '@shared/api';
import { AuthenticationFacadeService } from '../../../core/authentication/services/authentication-facade.service';
import { switchMap } from 'rxjs/operators';
import { InlineChat } from '../interfaces/inline-chat';
import { ChatPopupView } from '@features/chat/enums/chat-popup-view.enum';

@Injectable({
    providedIn: 'root'
})
export class ChatFacadeService {
    constructor(
        private store: Store<ChatAppState>,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    getChats(): Observable<InlineChat[]> {
        return this.store.select(ChatSelectors.chats);
    }

    getBadgeNumber(): Observable<number> {
        return this.authenticationFacadeService
            .getAuthenticatedPerson()
            .pipe(switchMap((loggedInUser) => this.store.select(ChatSelectors.badgeNumber(loggedInUser))));
    }

    /* List */

    getListInitialising(): Observable<boolean> {
        return this.store.select(ChatSelectors.list.initialising);
    }

    getFilteredChats(): Observable<InlineChat[]> {
        return this.authenticationFacadeService
            .getAuthenticatedPerson()
            .pipe(switchMap((loggedInUser) => this.store.select(ChatSelectors.list.searchedChats(loggedInUser))));
    }

    getPotentialChats(): Observable<SlimPeople[]> {
        return this.authenticationFacadeService
            .getAuthenticatedPerson()
            .pipe(switchMap((loggedInUser) => this.store.select(ChatSelectors.list.potentialChats(loggedInUser))));
    }

    /* Detail */

    getDetailPeer(): Observable<People> {
        return this.store.select(ChatSelectors.detail.peer);
    }

    getMessages(chatId: string): Observable<ChatMessage[]> {
        return this.store.select(ChatSelectors.messages(chatId));
    }

    getDetailInitialising(): Observable<boolean> {
        return this.store.select(ChatSelectors.detail.initialising);
    }

    getDetailSendingInProgress(): Observable<boolean> {
        return this.store.select(ChatSelectors.detail.sendingInProgress);
    }

    /* Popup */

    getPopupView(): Observable<ChatPopupView> {
        return this.store.select(ChatSelectors.popup.view);
    }

    getPopupChatId(): Observable<string> {
        return this.store.select(ChatSelectors.popup.chatId);
    }

    getPopupOpen(): Observable<boolean> {
        return this.store.select(ChatSelectors.popup.open);
    }

    dispatch(action: Action): void {
        this.store.dispatch(action);
    }
}
