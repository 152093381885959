<ng-container
    *ngIf="{
        initialising: initialising$ | async,
        messages: messages$ | async,
        loggedInUser: loggedInUser$ | async,
        peer: peer$ | async
    } as detailData"
>
    <div *ngIf="detailData.initialising" class="chat-detail__loader">
        <cc-loading-spinner></cc-loading-spinner>
    </div>
    <ng-container *ngIf="!detailData.initialising">
        <cc-person-header
            *ngIf="displayHeader"
            class="chat-detail__header"
            [person]="detailData.peer"
            [actions]="headerActions"
        ></cc-person-header>

        <cc-auto-scroll-bottom class="chat-detail__scroll-container" [items]="chatMessages" [useIonContent]="false">
            <ng-container *ngFor="let message of detailData.messages; index as i">
                <div *ngIf="isTimeBreak(i, message, detailData.messages[i - 1])" class="chat-detail__time-break">
                    {{ message.created | dateFormat: 'dateAndTime' }}
                </div>

                <cc-chat-message
                    #chatMessages
                    [message]="message.text"
                    [sentByPeer]="message.sender !== detailData.loggedInUser.id"
                    [user]="message.sender !== detailData.loggedInUser.id ? detailData.peer : detailData.loggedInUser"
                    @fadeIn
                >
                </cc-chat-message>
            </ng-container>
        </cc-auto-scroll-bottom>
    </ng-container>

    <cc-chat-detail-footer (send)="onSend($event)"></cc-chat-detail-footer>
</ng-container>
