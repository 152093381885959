import { Component, Input, OnInit } from '@angular/core';
import { SidebarType } from '@core/navigation/enums/sidebar-type.enum';
import { SidebarService } from '@core/navigation/services/sidebar.service';
import { NetworkStatusService } from '@core/pwa/services/network-status/network-status.service';
import { People } from '@shared/api';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertsFacadeService } from '../../../../features/alerts/services/alerts-facade.service';
import { ChatFacadeService } from '../../../../features/chat/services/chat-facade.service';
import { AppCoreFacadeService } from '../../../app-core/services/app-core-facade.service';
import { AuthenticationFacadeService } from '../../../authentication/services/authentication-facade.service';
import { NavigationHistoryService } from '../../../navigation-history/services/navigation-history.service';
import { AppRoutingActions } from '../../../root-store/store/app-routing/actions/app-routing.actions';
import { AppUserPageRoutes } from '../../../routing/constants/user-page-routes.constant';
import { ToolbarLeftType } from '../../enums/toolbar-left-type.enum';
import { ToolbarService } from '../../services/toolbar.service';
import { PwaUpdateService } from '@core/pwa/services/pwa-update/pwa-update.service';
import { ChatActions } from '@features/chat/store/actions/chat.actions';

@Component({
    selector: 'cc-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
    @Input()
    public shouldShowSidebar: boolean;

    /* Toolbar left*/
    public showMenuButton$: Observable<boolean>;
    public menuButtonType$: Observable<ToolbarLeftType>;
    public canGoBack$: Observable<boolean>;
    public isOffline$: Observable<boolean>;
    public popupOpen$: Observable<boolean>;

    /* Toolbar right */
    public chatBadge$: Observable<number>;
    public alertsBadge$: Observable<number>;
    public loggedInUser$: Observable<People>;
    public canChat$: Observable<boolean>;
    public updateAvailable$: Observable<boolean>;

    constructor(
        private toolbarService: ToolbarService,
        private chatFacadeService: ChatFacadeService,
        private alertsFacadeService: AlertsFacadeService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private sidebarService: SidebarService,
        private navigationHistoryService: NavigationHistoryService,
        private appCoreFacadeService: AppCoreFacadeService,
        private pwaUpdateService: PwaUpdateService,
        private networkStatusService: NetworkStatusService
    ) {}

    public ngOnInit(): void {
        this.menuButtonType$ = this.toolbarService.getMenuButtonType();
        this.showMenuButton$ = this.toolbarService.getShowMenuButton();
        this.canGoBack$ = this.navigationHistoryService.canGoBack();
        this.isOffline$ = this.networkStatusService.isOffline$;
        this.updateAvailable$ = this.pwaUpdateService.updateAvailable$;
        this.popupOpen$ = this.chatFacadeService.getPopupOpen();

        this.chatBadge$ = this.chatFacadeService.getBadgeNumber();
        this.alertsBadge$ = this.alertsFacadeService.getBadgeNumber();
        this.loggedInUser$ = this.authenticationFacadeService.getAuthenticatedPerson();

        this.canChat$ = combineLatest([
            this.appCoreFacadeService.getAppSettings().pipe(map((app) => app?.can_chat)),
            this.authenticationFacadeService
                .getAuthenticatedPerson()
                .pipe(map((loggedInUser) => loggedInUser && loggedInUser.chat_enabled))
        ]).pipe(map(([appChatEnabled, userChatEnabled]) => appChatEnabled && userChatEnabled));
    }

    public onMenuButtonClick(type: ToolbarLeftType): void {
        this.toolbarService.handleMenuButtonClick(type);
    }

    public onBackClick(): void {
        this.navigationHistoryService.back();
    }

    public onChatClick(): void {
        this.chatFacadeService.dispatch(ChatActions.openChats());
    }

    public onAlertsClick(): void {
        this.appCoreFacadeService.dispatch(
            AppRoutingActions.goToAppUserPage({ urlSegments: [AppUserPageRoutes.alerts] })
        );
    }

    public onUserClick(): void {
        this.sidebarService.open(SidebarType.Right);
    }

    public onUpdateClick(): void {
        this.pwaUpdateService.updateApp();
    }
}
