import { ChatState } from '../models/chat.state.model';
import { Action, createReducer, on } from '@ngrx/store';
import { ChatApiActions } from '../actions/chat-api.actions';
import { ChatDetailActions } from '../actions/chat-detail.actions';
import { ChatListActions } from '../actions/chat-list.actions';
import { ChatActions } from '../actions/chat.actions';
import { InlineChat } from '../../interfaces/inline-chat';
import { removeDuplicates } from '../../../../shared/utilities/sorting-utility.functions';
import { ChatPopupView } from '@features/chat/enums/chat-popup-view.enum';
import { ChatPopupActions } from '@features/chat/store/actions/chat-popup.actions';

export const initialState: ChatState = {
    initialising: true,
    chats: [],
    detail: undefined,
    list: undefined,
    messages: {},
    popup: {
        view: ChatPopupView.List,
        open: false
    }
};

const chatReducerFn = createReducer(
    initialState,
    on(ChatApiActions.getChatsSuccess, (state, { chats }) => ({
        ...state,
        initialising: false,
        chats
    })),
    on(ChatDetailActions.enter, (state, { chatId }) => ({
        ...state,
        detail: {
            initialising: true,
            peer: undefined,
            sendingInProgress: false,
            chatId
        }
    })),
    // Ably events
    on(ChatActions.realtimeNewMessage, (state, { message }) => {
        const chatId = message.chat;
        const existingMessages = state.messages[chatId] || [];
        const chatMessages = removeDuplicates([...existingMessages, message], (m1, m2) => m1.id === m2.id);
        const messages = {
            ...state.messages,
            [chatId]: chatMessages
        };
        const chat = state.chats.find((c) => c.id === chatId);
        const chatIndex = state.chats.indexOf(chat);
        return {
            ...state,
            messages,
            chats: [
                ...state.chats.slice(0, chatIndex),
                {
                    ...chat,
                    r_last_message: message as any
                },
                ...state.chats.slice(chatIndex + 1)
            ]
        };
    }),
    on(ChatApiActions.getInlineRealtimeChatSuccess, (state, { chat, message }) => ({
        ...state,
        chats: [...state.chats, chat],
        messages: {
            ...state.messages,
            [chat.id]: [message]
        }
    })),
    on(ChatApiActions.getChatDetailSuccess, (state, { chatId, messages, chat, peer }) => {
        const chats = [...state.chats].filter((c) => c.id !== chatId);
        return {
            ...state,
            detail: {
                ...state.detail,
                initialising: false,
                sendingInProgress: false,
                peer
            },
            messages: {
                ...state.messages,
                [chatId]: messages
            },
            chats: [...chats, chat]
        };
    }),
    on(ChatDetailActions.sendMessage, (state) => ({
        ...state,
        detail: {
            ...state.detail,
            sendingInProgress: true
        }
    })),
    on(ChatDetailActions.leave, (state) => ({
        ...state,
        detail: undefined
    })),
    on(ChatApiActions.sendMessageSuccess, (state, { message }) => {
        const chatId = message.chat;
        const messages = {
            ...state.messages,
            [chatId]: [...state.messages[chatId], message]
        };
        const chatIndex = state.chats.findIndex((c) => c.id === chatId);
        const chat = state.chats[chatIndex];
        return {
            ...state,
            detail: {
                ...state.detail,
                sendingInProgress: false
            },
            messages,
            chats: [
                ...state.chats.slice(0, chatIndex),
                {
                    ...chat,
                    r_last_message: message
                } as InlineChat,
                ...state.chats.slice(chatIndex + 1)
            ]
        };
    }),
    on(ChatApiActions.sendMessageFailure, (state) => ({
        ...state,
        detail: {
            ...state.detail,
            sendingInProgress: false
        }
    })),
    on(ChatListActions.submitSearch, (state, { searchTerm }) => ({
        ...state,
        list: {
            ...state.list,
            searchTerm,
            potentialChats: undefined
        }
    })),
    on(ChatApiActions.getPotentialChatsSuccess, (state, { potentialChats }) => ({
        ...state,
        list: {
            ...state.list,
            potentialChats
        }
    })),
    on(ChatApiActions.markReadSuccess, (state, { chatId, loggedInUserId }) => {
        if (!state.chats.length) {
            return state;
        }
        const chatIndex = state.chats.findIndex((c) => c.id === chatId);
        const oldChat = state.chats[chatIndex];
        const chat: InlineChat = oldChat.r_last_message
            ? {
                  ...oldChat,
                  r_last_message: {
                      ...oldChat.r_last_message,
                      read_by: [...new Set([...oldChat.r_last_message.read_by, loggedInUserId])]
                  }
              }
            : oldChat;
        return {
            ...state,
            chats: [...state.chats.slice(0, chatIndex), chat, ...state.chats.slice(chatIndex + 1)]
        };
    }),
    on(ChatApiActions.createChatSuccess, (state, { chat }) => {
        const chats = [...state.chats];
        if (!chats.find((c) => c.id === chat.id)) {
            chats.push(chat);
        }
        return {
            ...state,
            chats,
            messages: {
                ...state.messages,
                [chat.id]: state.messages[chat.id] || []
            }
        };
    }),
    on(ChatPopupActions.enterDetail, (state, { chatId }) => ({
        ...state,
        popup: {
            ...state.popup,
            view: ChatPopupView.Detail,
            chatId
        }
    })),
    on(ChatPopupActions.enterList, (state) => ({
        ...state,
        popup: {
            ...state.popup,
            view: ChatPopupView.List,
            chatId: undefined
        }
    })),
    on(ChatPopupActions.open, (state) => ({
        ...state,
        popup: {
            ...state.popup,
            open: true
        }
    })),
    on(ChatPopupActions.close, (state) => ({
        ...state,
        popup: {
            ...state.popup,
            open: false
        }
    })),
    on(ChatActions.clearChat, () => initialState)
);

export function chatReducer(state: ChatState | undefined, action: Action): ChatState {
    return chatReducerFn(state, action);
}
