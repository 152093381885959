import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { DialogService } from '@core/dialog/services/dialog/dialog.service';
import { AppRoutingActions } from '@core/root-store/store/app-routing/actions/app-routing.actions';
import { take } from 'rxjs/operators';
import { AppCoreFacadeService } from '../../core/app-core/services/app-core-facade.service';
import { DeviceService } from '../../core/device/services/device.service';
import { EnvironmentService } from '../../core/environment/services/environment.service';
import { WINDOW } from '../../core/window.service';
import { DownloadService } from './download/download.service';

@Injectable({
    providedIn: 'root'
})
export class UrlUtilityService {
    private readonly crowdcommsUrl = 'crowdcomms.com';
    private readonly meetingUrl = 'meet.crowdcomms.com';
    private readonly registrationPrefix = 'registration.';
    private readonly currentUrl = this.window.location.host;

    constructor(
        @Inject(WINDOW) private window: any,
        @Inject(DOCUMENT) public document: Document,
        private deviceService: DeviceService,
        private environmentService: EnvironmentService,
        private appCoreFacadeService: AppCoreFacadeService,
        private downloadService: DownloadService,
        private dialogService: DialogService
    ) {}

    public openPhoneNumber(phoneNumber: string): void {
        // Remove all non numeric or + characters
        const strippedNumber = phoneNumber.replace(new RegExp('[^0-9+]', 'g'), '');
        this.open(`tel:${strippedNumber}`);
    }

    public openEmail(email: string): void {
        this.open(`mailto:${email}`);
    }

    public async isAppUrl(url: string): Promise<boolean> {
        if (url.includes(this.registrationPrefix)) {
            return false;
        }

        const lowerCaseUrl = url.toLowerCase();
        const appSettings = await this.appCoreFacadeService.getAppSettings().pipe(take(1)).toPromise();

        const customDomains = appSettings.custom_domains?.split(',') ?? [];
        const appUrl = appSettings.url_name;

        const matchableDomains = [this.crowdcommsUrl, ...customDomains, this.currentUrl].map(
            (domain) => `${domain}/${appUrl}`
        );

        const matchesDomain = matchableDomains.some((domain) => lowerCaseUrl.includes(domain));
        const startsWithAppUrl = lowerCaseUrl.startsWith(`/${appUrl}`);
        const startsWithSlash = lowerCaseUrl.startsWith(`/`);

        return matchesDomain || startsWithAppUrl || startsWithSlash;
    }

    public isApiUrl(url: string): boolean {
        return url.toLowerCase().includes(this.environmentService.API_URL.toLowerCase());
    }

    public isMeetingUrl(url: string): boolean {
        return url.toLowerCase().includes(this.meetingUrl);
    }

    public openInApp(url: string): void {
        const l = this.document.createElement('a');
        l.href = url;

        const queryParams = this.stringToQueryParams(l.search);

        this.appCoreFacadeService.dispatch(
            AppRoutingActions.goToPage({
                urlSegments: l.pathname.split('/'),
                extras: { queryParams }
            })
        );
    }

    public async open(url: string): Promise<void> {
        if (!url || url.length === 0) {
            return;
        }

        const isMeetingUrl = this.isMeetingUrl(url);
        const isMailLink = url.includes('mailto:');

        if (isMeetingUrl || isMailLink) {
            this.window.open(url, '_system');
            return;
        }

        const isAppUrl = await this.isAppUrl(url);
        const isApiUrl = this.isApiUrl(url);

        if (isAppUrl && !isApiUrl) {
            this.openInApp(url);
            return;
        }

        if (isApiUrl) {
            this.downloadService.download(url);
            return;
        }

        if (url.includes(this.registrationPrefix)) {
            this.openExternalUrl(url);
            return;
        }

        const result = await this.dialogService
            .showConfirm({
                title: 'URL_UTILITY_WARNING_TITLE',
                message: 'URL_UTILITY_WARNING_MESSAGE'
            })
            .toPromise();

        if (!result) {
            return;
        }

        this.openExternalUrl(url);
    }

    // Convert search string to object of params in arrays
    // e.g. ?location=1&location=2&filter=1 -> { location: ['1', '2'], filter: ['1'] }
    public stringToQueryParams(search: string): Record<string, string[]> {
        if (!search) {
            return {};
        }
        // Convert search string to key-value tuple array
        const arrayParams: [string, string][] = [...(new URLSearchParams(search) as any)];
        const queryParams = arrayParams.reduce((acc, [field, value]) => {
            acc[field] = acc[field] ? [...acc[field], value] : [value];
            return acc;
        }, {});
        return queryParams;
    }

    private openExternalUrl(url: string): void {
        if (this.deviceService.isSafari() || this.deviceService.isIos()) {
            this.window.location.assign(url);
        } else {
            this.window.open(url, '_blank');
        }
    }
}
