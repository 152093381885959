import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { People } from '@api';
import emojiRegex from 'emoji-regex';

@Component({
    selector: 'cc-chat-message',
    templateUrl: './chat-message.component.html',
    styleUrls: ['./chat-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements OnChanges {
    @Input()
    public user: People;

    @Input()
    public message: string;

    @Input()
    public sentByPeer: boolean;

    public isEmojiOnly: boolean;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.message) {
            this.isEmojiOnly = this.containsOnlyEmojis(this.message);
        }
    }

    private containsOnlyEmojis(text: string): boolean {
        const visibleCharsRegex = new RegExp(/[\n\r]+|( )+/g);

        const emojiMatches = text.match(emojiRegex()) ?? [];
        const emojis = emojiMatches.join('');
        const visibleChars = text.replace(visibleCharsRegex, '');

        return emojis.length === visibleChars.length;
    }
}
