import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { Title } from '@angular/platform-browser';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AlertsFacadeService } from '@features/alerts/services/alerts-facade.service';
import { ChatFacadeService } from '@features/chat/services/chat-facade.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CustomRouteData } from '@core/routing/types/custom-route-data';
import { TitleSelectors } from '@core/routing/constants/title-selectors.constant';
import { RouteTitleData } from '@core/routing/types/route-title-data';
import { AppState } from '@core/root-store/store/app-state.model';

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    appName$: Observable<string>;
    pageTitle$ = new ReplaySubject<string>(1);
    notifications$: Observable<number>;

    constructor(
        private appCoreFacadeService: AppCoreFacadeService,
        private title: Title,
        private store: Store<AppState>,
        private chatFacadeService: ChatFacadeService,
        private alertsFacadeService: AlertsFacadeService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService
    ) {}

    initialise(): void {
        this.setupTitleParts();

        combineLatest([this.notifications$, this.pageTitle$, this.appName$])
            .pipe(map(([notifications, pageTitle, appName]) => this.getTitle(notifications, pageTitle, appName)))
            .subscribe((newTitle) => this.title.setTitle(newTitle));
    }

    setPageTitle(pageTitle: string): void {
        this.pageTitle$.next(pageTitle);
    }

    private setupTitleParts(): void {
        this.appName$ = this.appCoreFacadeService.getAppSettings().pipe(
            map((app) => app?.name),
            distinctUntilChanged()
        );

        this.notifications$ = combineLatest([
            this.chatFacadeService.getBadgeNumber(),
            this.alertsFacadeService.getBadgeNumber()
        ]).pipe(
            map(([chats, alerts]) => chats + alerts),
            distinctUntilChanged()
        );

        this.titleData()
            .pipe(
                switchMap((titleData) => this.transformTitleData(titleData)),
                filter((pageTitle) => !!pageTitle),
                distinctUntilChanged()
            )
            .subscribe((pageTitle) => {
                this.setPageTitle(pageTitle);
            });
    }

    private titleData(): Observable<RouteTitleData> {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(undefined), // Ensure initial page title data is fetched
            map(() => this.findDeepestTitle(this.activatedRoute.snapshot)),
            filter((titleData) => !!titleData)
        );
    }

    private transformTitleData(titleData: RouteTitleData): Observable<string | undefined> {
        if (titleData.static) {
            return this.translateService.get(titleData.static);
        }
        if (titleData.fromSelector) {
            const selector = TitleSelectors[titleData.fromSelector];
            return this.store.select(selector);
        }
    }

    private getTitle(notifications: number, pageTitle: string, appName: string): string {
        let title = '';
        if (notifications > 0) {
            title += `(${notifications}) `;
        }
        if (pageTitle && appName) {
            title += `${pageTitle} | ${appName}`;
        } else if (pageTitle) {
            title += pageTitle;
        } else if (appName) {
            title += appName;
        }
        return title;
    }

    // Find the deepest route which has a title associated with it
    private findDeepestTitle(route: ActivatedRouteSnapshot): RouteTitleData | undefined {
        let deepestTitle;
        while (route.firstChild) {
            route = route.firstChild;
            const routeData: CustomRouteData = route.data;
            if (routeData.title) {
                deepestTitle = routeData.title;
            }
        }
        return deepestTitle;
    }
}
